import { NumberInput } from '@angular/cdk/coercion';
import { ElementRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ConfirmDialogComponent } from '@levent/core/components/confirm-dialog';
import { Id, IConfirmData } from '@levent/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	constructor(private readonly store: Store, private readonly window: Window, private readonly dialog: MatDialog) {}

	public static getCorrectArrIds(payload: Id | Id[]): Id[] {
		const value = payload ?? [];
		return Array.isArray(value) ? value : [value];
	}

	public toBase64(file: File): Observable<string | ArrayBuffer> {
		return new Observable(subscriber => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				subscriber.next(reader.result);
				subscriber.complete();
			};
			reader.onerror = error => {
				subscriber.error(error);
				subscriber.complete();
			};
		});
	}

	/**
	 * Copy properties to target object
	 * @param target
	 * @param props
	 * */
	public static COPY_PROPS(target: any, props: any): void {
		for (const key in props) {
			if (props.hasOwnProperty(key)) {
				target[key] = props[key];
			}
		}
	}

	/**
	 * Show toast message with error
	 * @param {object} error
	 */
	public onError(error: any): void {
		// TODO: need logic
		// eslint-disable-next-line no-console
		console.log(error);
	}

	/**
	 * The HTMLElement.focus() method sets focus on the specified element, if it can be focused.
	 * The focused element is the element which will receive keyboard and similar events by default.
	 * @param {ElementRef} element
	 */
	public focus(element: ElementRef): void {
		if (element && element.nativeElement) {
			element.nativeElement.focus();
		}
	}

	/**
	 * sort with Collator
	 */
	public static sort(): (data: any[], sort: MatSort) => any[] {
		const collator = new Intl.Collator(undefined, {
			numeric: true,
			ignorePunctuation: true,
			sensitivity: 'base',
		});
		return (data: any[], sort: MatSort) => {
			let result = data.sort((a: any, b: any) => {
				return collator.compare((a as any)[sort.active], (b as any)[sort.active]);
			});
			if (sort.direction === 'desc') {
				result = result.reverse();
			}
			return result;
		};
	}

	public showConfirmationDialog(data: IConfirmData, config?: MatDialogConfig): Observable<boolean | NumberInput> {
		// TODO: check other options
		const doc = this.window.document;
		doc.body.classList.add('body-blurred');

		return this.dialog
			.open(ConfirmDialogComponent, {
				backdropClass: ['lev-dialog-backdrop'],
				maxWidth: '460px',
				...config,
				data,
			})
			.afterClosed()
			.pipe(tap(() => doc.body.classList.remove('body-blurred')));
	}
}
