import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EConditionType, IComplexCondition, Id, IUICondition } from '@levent/core';
import { IUIComplexCondition } from './ui-complex-condition.interface';

export class DefaultUIComplexCondition<T extends IComplexCondition> implements IUIComplexCondition<T> {
	public instrumentId: Id;
	public conditions: IUICondition[] = [];
	public conditionFormGroup: FormGroup;

	public get conditionArray(): FormArray {
		return this.conditionFormGroup.controls['conditions'] as FormArray;
	}

	public get value(): T {
		return this.conditionFormGroup.getRawValue();
	}

	public get valid(): boolean {
		return this.conditionFormGroup.valid;
	}

	public get exitConditionCount(): number {
		return this.conditions.filter(condition => condition.valid && condition.conditionType === EConditionType.Exit)
			.length;
	}

	public get entryConditionCount(): number {
		return this.conditions.filter(condition => condition.valid && condition.conditionType === EConditionType.Entry)
			.length;
	}

	constructor(props: Partial<T>) {
		this.buildConditionFormGroup();
		this.patchValue(props);
		this.setInstrumentId(props.instrumentId);
	}

	public buildConditionFormGroup(isConditionsRequired: boolean = true): void {
		this.conditionFormGroup = new FormGroup({
			instrumentId: new FormControl(this.instrumentId, [Validators.required]),
			conditions: new FormArray([], isConditionsRequired ? [Validators.required] : []),
		});
	}

	public setInstrumentId(value: Id): void {
		this.instrumentId = value;
		this.patchValue({ instrumentId: value } as unknown as Partial<T>);
	}

	public patchValue(value: Partial<T>): void {
		const { conditions, ...newValue } = value;
		this.conditionFormGroup.patchValue(newValue);
	}

	public addUICondition(value: IUICondition): void {
		this.conditions = [...this.conditions, value];
		this.conditionArray.push(this.conditions[this.conditions.length - 1].conditionFormGroup);
	}

	public removeUICondition(value: IUICondition): void {
		const index = this.conditions.findIndex(condition => condition.getConditionId() === value.getConditionId());
		if (index > -1) {
			this.conditions = this.conditions.filter(
				condition => condition.getConditionId() !== value.getConditionId(),
			);
			this.conditionArray.removeAt(index);
		}
	}
}
