import { IBreadcrumb, IBrokerInfo, IPerson, RightSidebar } from '@levent/core';
import { CoreStateModel, CoreState } from '@lev-store/core';
import { Selector } from '@ngxs/store';
import { PagesState } from './pages-state';

import { PagesStateModel } from './pages-state.model';

export class PagesSelectors {
	@Selector([PagesState, CoreState])
	public static pending(pageState: PagesStateModel, coreState: CoreStateModel): boolean {
		return !coreState.pending ? pageState.pending : false;
	}

	@Selector([PagesState])
	public static breadcrumbs(state: PagesStateModel): IBreadcrumb[] {
		return state.breadcrumbs;
	}

	@Selector([PagesState])
	public static userInfo(state: PagesStateModel): IPerson {
		return state.userInfo;
	}

	@Selector([PagesState])
	public static strategyHistory(state: PagesStateModel): boolean {
		return state.strategyHistory;
	}

	@Selector([PagesState])
	public static rightSidebar(state: PagesStateModel): RightSidebar {
		return state.rightSidebar;
	}

	@Selector([PagesState])
	public static brokerInfo(state: PagesStateModel): IBrokerInfo {
		return state.brokerInfo;
	}
}
