import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ACTIVE_THEME, ThemeOptions, THEMES } from './symbols';

import { ThemeService } from './theme.service';

@NgModule({
	imports: [CommonModule],
	providers: [ThemeService],
})
export class ThemeModule {
	public static forRoot(options: ThemeOptions): ModuleWithProviders<ThemeModule> {
		return {
			ngModule: ThemeModule,
			providers: [
				{
					provide: THEMES,
					useValue: options.themes,
				},
				{
					provide: ACTIVE_THEME,
					useValue: options.active,
				},
			],
		};
	}
}
