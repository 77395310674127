import { IUser } from '@levent/core';
import { IUserConfiguration } from '@levent/core/DTO/models/user-configuration';

export class AuthStateModel {
	constructor(
		public pending: boolean = false,
		public token: string = '',
		public refreshToken: string = '',
		public expiresIn: number = null,
		public user: Partial<IUser> = {},
		public userConfigurationDetails: Partial<IUserConfiguration> = {},
	) {}
}

export const defaults: AuthStateModel = new AuthStateModel();
