const host = 'https://dev.levent.io';

export const environment = {
	production: true,
	SERVICES: {
		IDENTITY: {
			NAME: 'identityserverservice',
			PATH: `${host}/identityserverservice`,
		},
		GATEWAY: {
			NAME: 'gatewayservice',
			PATH: `${host}/gatewayservice`,
		},
		WS_NOTIFICATION: {
			NAME: 'wsnotificationservice',
			PATH: `${host}/wsnotificationservice`,
		},
	},
	CLIENT_ID: 'sLlTgHn3yKRNVP0C3hQ6nIJohuq32Ks4',
	API_VERSION: `v1`,
	USE_HASH: false,
};
