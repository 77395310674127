import { EStrategyStatus } from '@levent/core';
import { UtilsService } from '@levent/core/services';

export class GetStrategiesParams {
	public page: number = 1;
	public count: number = 20;
	public search?: string;
	public statuses?: EStrategyStatus;
	constructor(args?: GetStrategiesParams) {
		UtilsService.COPY_PROPS(this, args);
	}
}

export class GetAlertsParams {
	public page: number = 1;
	public count: number = 20;
	public search?: string;
	public status?: number; // TODO: check enum from backend
	constructor(args?: GetAlertsParams) {
		UtilsService.COPY_PROPS(this, args);
	}
}
