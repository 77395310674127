import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { first } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { AuthSkipInterceptor } from '@levent/core/constants/interceptor-skips';
import {
	AuthSelectors,
	ErrorLoginAction,
	LoginWithRefreshTokenAction,
	LogoutAction,
	SuccessLoginAction,
} from '@lev-store/page/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private readonly store: Store, private readonly actions$: Actions) {}
	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(this.getAuthorizedRequest(request)).pipe(
			catchError((response: HttpErrorResponse) => {
				let result: Observable<never | HttpEvent<any>> = throwError(() => response);
				const needToAuthenticate = response.status === 401;
				if (needToAuthenticate) {
					const refreshToken = this.store.selectSnapshot(AuthSelectors.refreshToken);
					if (refreshToken) {
						this.store.dispatch(new LoginWithRefreshTokenAction());
						result = this.actions$.pipe(
							ofActionSuccessful(SuccessLoginAction, ErrorLoginAction),
							first(),
							switchMap(action => {
								let nextReq = next.handle(this.getAuthorizedRequest(request));
								if (action instanceof ErrorLoginAction) {
									nextReq = response.error;
								}
								return nextReq;
							}),
						);
					} else {
						this.store.dispatch(new LogoutAction());
					}
				}
				return result;
			}),
		);
	}

	private getAuthorizedRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
		const token = this.store.selectSnapshot(AuthSelectors.token);
		if (token) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${token}` },
			});
		}
		if (request.headers.has(AuthSkipInterceptor)) {
			const headers = request.headers.delete(AuthSkipInterceptor).delete('Authorization');
			request = request.clone({ headers });
		}
		return request;
	}
}
