<div class="confirm-dialog-container" direction="column">
	<div class="confirm-dialog__close-action" horizontal="end" *ngIf="!hiddenCloseButton">
		<button [mat-dialog-close]="null" class="lev-btn icon-plus"></button>
	</div>
	<div class="confirm-dialog__title">{{ title | translate }}</div>
	<div class="confirm-dialog__description">
		<ng-container *ngIf="descriptionTemplate; else textTpl">
			<ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
		</ng-container>
		<ng-template #textTpl>{{ description | translate }}</ng-template>
	</div>
	<div class="confirm-dialog__actions" vertical="center" horizontal="center">
		<ng-container *ngIf="alertMode; else defaultActionsTpl">
			<button class="lev-btn confirm-action" [mat-dialog-close]="confirmValue">
				{{ confirmText | translate }}
			</button>
		</ng-container>
		<ng-template #defaultActionsTpl>
			<button class="lev-btn" [mat-dialog-close]="cancelValue">{{ cancelText | translate }}</button>
			<button class="lev-btn confirm-action" [mat-dialog-close]="confirmValue">
				{{ confirmText | translate }}
			</button>
		</ng-template>
	</div>
</div>
