import { CdkPortal, ComponentPortal } from '@angular/cdk/portal';
import { MatDrawerMode } from '@angular/material/sidenav';
type PortalType = CdkPortal | ComponentPortal<unknown>;

export class RightSidebar {
	public name: string = '';
	public opened: boolean = false;
	public template: PortalType = null;
	public hasBackdrop?: boolean = false;
	public mode?: MatDrawerMode = 'over';
	constructor(args?: Partial<RightSidebar>) {
		this.name = args?.name || '';
		this.opened = args?.opened || false;
		this.template = args?.template || null;
		this.hasBackdrop = args?.hasBackdrop || false;
		this.mode = args?.mode || 'over';
	}
}
