import { Theme, ThemesEnum } from './symbols';
import { EThemeProperties } from '@levent/core';
import { ANIMATION_DURATION } from '@levent/core/animations';

export class DefaultTheme implements Theme {
	public name: ThemesEnum;
	public properties = {
		[EThemeProperties.REGISTRATION_BUTTON_TEXT]: '#cdcdd9',
		[EThemeProperties.GO_SIGN_IN_BUTTON]: '#6e6e76',
		[EThemeProperties.GO_REGISTRATION]: '#d2d2e6',
		[EThemeProperties.APP_BACKGROUND]: 'radial-gradient(100% 132.5% at 100% 0%, #272845 0%, #100D1B 100%)',
		[EThemeProperties.APP_ANIMATION_DURATION]: `${ANIMATION_DURATION}ms`,
		[EThemeProperties.PRIMARY_TEXT_COLOR]: '#D2D2E6',
		[EThemeProperties.FORM_FIELD_BACKGROUND]: '#1c1b30',
		[EThemeProperties.APP_BORDER_COLOR]: 'rgba(113, 114, 134, 0.5)',
		[EThemeProperties.RIPPLE_COLOR]: 'rgba(255, 255, 255, .1)',
		[EThemeProperties.TOGGLE_CHECKED]: '#4FFFA8',
		[EThemeProperties.TOGGLE_UNCHECKED]: '#D2D2E6',
		[EThemeProperties.DISABLED_CHECKBOX_BORDER_FRAME]: '#717286',
		[EThemeProperties.DISABLED_CHECKMARK_PATH]: '#ffffff',
		[EThemeProperties.CHECKBOX_BORDER_FRAME]: '#aeafc5',
		[EThemeProperties.CHECKBOX_CHECKED_BACKGROUND]: '#1AB6D9',
		[EThemeProperties.CHECKBOX_BACKGROUND]: 'transparent',
		[EThemeProperties.BACKGROUND_NONE]: 'background-none',
		[EThemeProperties.STROKE_COLOR]: '#000',
		[EThemeProperties.TOGGLE_DISABLED]: '#D2D2E6',
		[EThemeProperties.PRIMARY_ACTIVE_COLOR]: '#1AB6D9',
		[EThemeProperties.PRIMARY_ACTIVE_TEXT_COLOR]: '#ffffff',
		[EThemeProperties.BADGE_TEXT_COLOR]: '#252830',
		[EThemeProperties.BADGE_SHORT_SELL_BACKGROUND]: '#fca143',
		[EThemeProperties.BADGE_BUY_BACKGROUND]: '#4fffa8',
		[EThemeProperties.BADGE_SELL_BACKGROUND]: '#ff6189',
		[EThemeProperties.PRICE_UP_COLOR]: '#27DA82',
		[EThemeProperties.PRICE_DOWN_COLOR]: '#da2755',
		[EThemeProperties.ACTIVE_STATUS_COLOR]: '#16d1a8',
		[EThemeProperties.INACTIVE_STATUS_COLOR]: '#da2755',
		[EThemeProperties.IN_POSITION_STATUS_COLOR]: '#dff823',
		[EThemeProperties.DRAFT_STATUS_COLOR]: '#C2D9F4',
		[EThemeProperties.EXECUTED_STATUS_COLOR]: '#717286',
		[EThemeProperties.EJECTED_STATUS_COLOR]: '#717286',
		[EThemeProperties.REJECTED_STATUS_COLOR]: '#717286',
		[EThemeProperties.CANCELLED_STATUS_COLOR]: '#717286',
		[EThemeProperties.TEMPLATE_STATUS_COLOR]: '#717286',
		[EThemeProperties.TERTIARY_TEXT_COLOR]: '#C2D9F4',
		[EThemeProperties.CARD_BACKGROUND]: '#101224',
		[EThemeProperties.CARD_BACKGROUND_FOR_NEW]:
			'linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.05) 100%)',
		[EThemeProperties.DEEP_BACKGROUND]: '#101224',
		[EThemeProperties.SEMI_BOLD_FONT_WEIGHT]: 600,
		[EThemeProperties.MEDIUM_FONT_WEIGHT]: 500,
		[EThemeProperties.REGULAR_FONT_WEIGHT]: 400,
		[EThemeProperties.SECONDARY_TEXT_COLOR]: '#717286',
		[EThemeProperties.STOCKS_GRADIENT]:
			'linear-gradient(180deg, rgba(21, 236, 173, 0.5) 0%, rgba(58, 195, 235, 0) 100%)',
		[EThemeProperties.FOREX_GRADIENT]:
			'linear-gradient(180deg, rgba(210, 109, 227, 0.5) 0%, rgba(98, 79, 182, 0) 100%)',
		[EThemeProperties.CRYPTO_GRADIENT]:
			'linear-gradient(180deg, rgba(234, 161, 61, 0.5) 0%, rgba(172, 84, 80, 0) 100%)',
		[EThemeProperties.STOCKS_GRADIENT_STROKE]: '#0C84A5',
		[EThemeProperties.STOCKS_GRADIENT_FROM]: 'rgba(12, 132, 165, 0.3)',
		[EThemeProperties.STOCKS_GRADIENT_TO]: 'rgba(12, 132, 165, 0)',
		[EThemeProperties.FOREX_GRADIENT_STROKE]: '#604FB6',
		[EThemeProperties.FOREX_GRADIENT_FROM]: 'rgba(214, 108, 227, 0.3)',
		[EThemeProperties.FOREX_GRADIENT_TO]: 'rgba(214, 108, 227, 0)',
		[EThemeProperties.CRYPTO_GRADIENT_STROKE]: '#DC5419',
		[EThemeProperties.CRYPTO_GRADIENT_FROM]: 'rgba(236, 130, 28, 0.3)',
		[EThemeProperties.CRYPTO_GRADIENT_TO]: 'rgba(236, 130, 28, 0)',
	};
	constructor(name: ThemesEnum, properties: any) {
		this.name = name;
		Object.keys(properties).forEach(key => {
			this.properties[key] = properties[key];
		});
	}
}
