import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
const noop = () => {};

@Directive()
export class ValueAccessor<T> implements ControlValueAccessor {
	@Input()
	public get disabled(): boolean {
		return this._disabled;
	}

	public set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
	}
	protected _disabled: boolean = false;
	@HostListener('blur') public onBlur(): void {
		this.onTouched();
	}
	@Input()
	public get value(): T {
		return this._value;
	}
	public set value(value: T) {
		this._value = value;
	}
	protected _value: T = null;
	@Output() public valueChange: EventEmitter<T> = new EventEmitter<T>();

	public onChange: (value: any) => void = noop;
	public onTouched: () => void = noop;
	public writeValue(value: any): void {
		if (value !== this.value) {
			this.value = value;
		}
	}

	public registerOnChange(fn: (value: T) => void): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
export function makeProvider(component: any): any {
	return {
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => component),
		multi: true,
	};
}
