import { EIndicatorType, ICondition } from '@levent/core';
import { UiConditionTradeNow } from '@levent/core/models/ui-condition/ui-condition-trade-now.model';
import { UIConditionIndicator } from './ui-condition-indicator.model';
import { UIConditionByPrice } from './ui-condition-by-price.model';
import { UIConditionBySpread } from './ui-condition-by-spread.model';
import { UIConditionByVolume } from './ui-condition-by-volume.model';
import { IUIConditionIndicator } from './ui-condition-indicator.interface';
import { UIConditionLoss } from './ui-condition-loss.model';
import { UIConditionProfit } from './ui-condition-profit.model';
import { UIConditionTrailingStop } from './ui-condition-trailing-stop.model';
import { IUICondition } from './ui-condition.interface';

export class UIConditionFactory {
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.TradeNow,
		properties: Partial<T & { isBenchmark: boolean }>,
	): UiConditionTradeNow<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.Price,
		properties: Partial<T & { isBenchmark: boolean }>,
	): UIConditionByPrice<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.Volume,
		properties: Partial<T>,
	): UIConditionByVolume<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.TrailingStop,
		properties: Partial<T>,
	): UIConditionTrailingStop<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.Profit,
		properties: Partial<T>,
	): UIConditionProfit<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.Loss,
		properties: Partial<T>,
	): UIConditionLoss<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.Spread,
		properties: Partial<T>,
	): UIConditionBySpread<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType.Indicator,
		properties: Partial<T & { isBenchmark: boolean }>,
	): IUIConditionIndicator<T>;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType,
		properties: Partial<T & { isBenchmark: boolean }>,
	): IUICondition;
	public static create<T extends ICondition>(
		indicatorType: EIndicatorType,
		properties: Partial<T & { isBenchmark: boolean }>,
	): IUICondition {
		switch (indicatorType) {
			case EIndicatorType.TradeNow:
				return new UiConditionTradeNow(properties);
			case EIndicatorType.Price:
				return new UIConditionByPrice(properties);
			case EIndicatorType.Volume:
				return new UIConditionByVolume(properties);
			case EIndicatorType.TrailingStop:
				return new UIConditionTrailingStop(properties);
			case EIndicatorType.Profit:
				return new UIConditionProfit(properties);
			case EIndicatorType.Loss:
				return new UIConditionLoss(properties);
			case EIndicatorType.Spread:
				return new UIConditionBySpread(properties);
			case EIndicatorType.Indicator:
				return new UIConditionIndicator(properties);
			default:
				throw new Error('Wrong indicator type chosen for UICondition.');
		}
	}
}
