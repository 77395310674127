import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SetRightSidebarAction } from '@lev-store/page';
import { InstrumentsSelectors } from '@lev-store/shared/instruments';
import { EAlertType, EApi, ERoutes, IAlert } from '@levent/core';
import { Action, State, StateContext, Store } from '@ngxs/store';

import { finalize, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
	ActivateAlertAction,
	CreateAlertByInstrumentIdAction,
	CreateWizardAlertAction,
	UpdateAlertAction,
	UpdateWizardAlertAction,
} from './alert-wizard.actions';
import { AlertWizardStateModel, defaults } from './alert-wizard-state.model';

import * as moment from 'moment';
import { Router } from '@angular/router';

@State<AlertWizardStateModel>({
	name: 'alertWizard',
	defaults,
})
@Injectable()
export class AlertWizardState {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly store: Store,
		private readonly router: Router,
	) {}

	@Action(CreateAlertByInstrumentIdAction) public createAlertByInstrumentId(
		{ patchState, dispatch }: StateContext<AlertWizardStateModel>,
		{ instrumentId }: CreateAlertByInstrumentIdAction,
	): void {
		const instrument = this.store.selectSnapshot(InstrumentsSelectors.instrumentMapById)[instrumentId];
		const creationTime = moment().format('HH:mm DD.MM.YYYY');
		const alert: Partial<IAlert> = {
			type: EAlertType.Easy,
			name: `${instrument.symbol}  ${creationTime}`,
			complexConditions: [{ instrumentId, conditions: [] }],
			repeat: false,
			start: true,
		};
		dispatch([new UpdateAlertAction(alert), new SetRightSidebarAction(null, true)]);
	}

	@Action(UpdateAlertAction)
	public updateAlertAction({ patchState }: StateContext<AlertWizardStateModel>, { alert }: UpdateAlertAction): void {
		patchState({ model: alert, dirty: false });
	}

	@Action(ActivateAlertAction)
	public activateAlertAction({ getState, dispatch }: StateContext<AlertWizardStateModel>): void {
		const { model } = getState();
		if (model.id) {
			dispatch(new UpdateWizardAlertAction(model));
		} else {
			dispatch(new CreateWizardAlertAction(model));
		}
	}

	@Action(CreateWizardAlertAction) public createWizardAlert(
		{ dispatch, patchState }: StateContext<AlertWizardStateModel>,
		{ payload }: CreateWizardAlertAction,
	): Observable<IAlert> {
		payload.name = payload.name.trim();
		return this.httpClient.post<IAlert>(`${EApi.ALERTS}`, payload).pipe(
			tap((alert: IAlert) => {
				dispatch(new UpdateAlertAction(alert));
				this.router.navigateByUrl(`${ERoutes.ALERTS}`).then();
			}),
			finalize(() => patchState({ pending: false })),
		);
	}

	@Action(UpdateWizardAlertAction) public updateWizardAlert(
		{ dispatch, patchState }: StateContext<AlertWizardStateModel>,
		{ payload }: UpdateWizardAlertAction,
	): Observable<IAlert> {
		payload.name = payload.name.trim();
		return this.httpClient.put<IAlert>(`${EApi.ALERTS}/${payload.id}`, payload).pipe(
			tap((alert: IAlert) => {
				dispatch(new UpdateAlertAction(alert));
				this.router.navigateByUrl(`${ERoutes.ALERTS}`).then();
			}),
			finalize(() => patchState({ pending: false })),
		);
	}
}
