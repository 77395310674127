import { IStrategy } from '@levent/core';
import { StrategyWizardStateModel } from './strategy-wizard-state.model';
import { StrategyWizardState } from './strategy-wizard-state';
import { Selector } from '@ngxs/store';

export class StrategyWizardSelectors {
	@Selector([StrategyWizardState])
	public static pending(state: StrategyWizardStateModel): boolean {
		return state.pending;
	}
	@Selector([StrategyWizardState])
	public static strategy(state: StrategyWizardStateModel): Partial<IStrategy> {
		return state.model;
	}
}
