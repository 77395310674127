import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { SetRightSidebarAction } from '@lev-store/page';
import { InstrumentsSelectors } from '@lev-store/shared/instruments';
import { EApi, EStrategyStatus, EStrategyType, IStrategy } from '@levent/core';
import { UtilsService } from '@levent/core/services';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import moment from 'moment-timezone';

import { defaults, StrategyWizardStateModel } from './strategy-wizard-state.model';

import {
	ActivateStrategyAction,
	CreateStrategyByInstrumentIdAction,
	CreateStrategyByTemplateAction,
	CreateWizardStrategyAction,
	UpdateStrategyAction,
	UpdateWizardStrategyAction,
} from './strategy-wizard.actions';

@State<StrategyWizardStateModel>({
	name: 'strategyWizard',
	defaults,
})
@Injectable()
export class StrategyWizardState {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly utilsService: UtilsService,
		private readonly store: Store,
		private readonly location: Location,
	) {}

	@Action(CreateStrategyByInstrumentIdAction)
	public createStrategyByInstrumentId(
		{ dispatch }: StateContext<StrategyWizardStateModel>,
		{ instrumentId }: CreateStrategyByInstrumentIdAction,
	): void {
		const instrument = this.store.selectSnapshot(InstrumentsSelectors.instrumentMapById)[instrumentId];
		const creationTime = moment().format('HH:mm DD.MM.YYYY');
		dispatch([
			new SetRightSidebarAction(null, true),
			new UpdateStrategyAction({
				type: EStrategyType.Fast,
				name: `${instrument.symbol}  ${creationTime}`,
				complexConditions: [{ instrumentId, conditions: [], quantity: null, buySell: null }],
			}),
		]);
	}

	@Action(CreateStrategyByTemplateAction)
	public CreateStrategyByTemplate(
		{ dispatch }: StateContext<StrategyWizardStateModel>,
		{ template }: CreateStrategyByTemplateAction,
	): void {
		const creationTime = moment().format('HH:mm DD.MM.YYYY');
		dispatch([
			new SetRightSidebarAction(null, true),
			new UpdateStrategyAction({
				type: EStrategyType.Advanced,
				name: `${template.complexConditions[0].instrumentInfo.name}  ${creationTime}`,
				complexConditions: template.complexConditions,
			}),
		]);
	}

	@Action(UpdateStrategyAction) public updateStrategyAction(
		{ patchState }: StateContext<StrategyWizardStateModel>,
		{ strategy }: UpdateStrategyAction,
	): void {
		patchState({ model: strategy, dirty: false });
	}

	@Action(ActivateStrategyAction) public activeStrategy({
		getState,
		dispatch,
		patchState,
	}: StateContext<StrategyWizardStateModel>): void {
		const { model } = getState();
		if (model.id) {
			dispatch(new UpdateWizardStrategyAction(model));
		} else {
			// this.utilsService
			// 	.showConfirmationDialog({
			// 		title: 'STRATEGY_WIZARD.ARE_YOU_SURE_YOU_WANT_TO_SHARE_THE_STRATEGY',
			// 	})
			// 	.subscribe(confirmed => {
			// 		if (confirmed) {
			// 			// TODO: share the strategies with all users.
			// 		}
			// 	});
			dispatch(new CreateWizardStrategyAction(model));
		}
	}

	@Action(CreateWizardStrategyAction) public createWizardStrategy(
		{ dispatch, patchState }: StateContext<StrategyWizardStateModel>,
		{ payload }: CreateWizardStrategyAction,
	): Observable<IStrategy> {
		payload.name = payload.name.trim();
		// TODO: remove after update backend
		if (payload.startTime) {
			const startTime = payload.startTime.split('.');
			payload.startTime = `${startTime[0]}Z`;
		}
		if (payload.endTime) {
			const endTime = payload.endTime.split('.');
			payload.endTime = `${endTime[0]}Z`;
		}
		return this.httpClient.post<IStrategy>(`${EApi.STRATEGIES}`, payload).pipe(
			tap((strategy: IStrategy) => {
				dispatch(new UpdateStrategyAction(strategy));
				this.utilsService
					.showConfirmationDialog({
						title: 'SHARED.YOUR_STRATEGY_IS_CREATE',
						alertMode: true,
					})
					.subscribe(() => {
						this.location.back();
					});
				patchState({ pending: false });
			}),
			catchError(error => {
				patchState({ pending: false });
				return throwError(() => error);
			}),
		);
	}

	@Action(UpdateWizardStrategyAction) public updateWizardStrategy(
		{ dispatch, patchState }: StateContext<StrategyWizardStateModel>,
		{ payload }: UpdateWizardStrategyAction,
	): Observable<IStrategy> {
		payload.name = payload.name.trim();
		// TODO: remove after update backend
		if (payload.startTime) {
			const startTime = payload.startTime.split('.');
			payload.startTime = `${startTime[0]}Z`;
		}
		if (payload.endTime) {
			const endTime = payload.endTime.split('.');
			payload.endTime = `${endTime[0]}Z`;
		}

		return this.httpClient.put<IStrategy>(`${EApi.STRATEGIES}/${payload.id}`, payload).pipe(
			tap((strategy: IStrategy) => {
				dispatch(new UpdateStrategyAction(strategy));
				this.location.back();
				if (payload.status !== EStrategyStatus.Draft) {
					this.httpClient
						.post(`${EApi.STRATEGIES}/${payload.id}/${EApi.START}`, null)
						.pipe(take(1))
						.subscribe();
				}
				patchState({ pending: false });
			}),
			catchError(error => {
				patchState({ pending: false });
				return throwError(() => error);
			}),
		);
	}
}
