import { IAlert } from '@levent/core';

export class AlertWizardStateModel {
	constructor(
		public pending: boolean = false,
		public model: Partial<IAlert> = {},
		public dirty: boolean = false,
		public status: string = '',
		public errors: any = {},
	) {}
}

export const defaults: AlertWizardStateModel = new AlertWizardStateModel();
