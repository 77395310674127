export enum EThemeProperties {
	APP_BACKGROUND = '--app-background',
	APP_ANIMATION_DURATION = '--app-animation-duration',
	REGISTRATION_BUTTON_TEXT = '--registration-button_text',
	GO_SIGN_IN_BUTTON = '--go-sign-in-button',
	GO_REGISTRATION = '--go-registration',
	FORM_FIELD_BACKGROUND = '--form-field-background',
	APP_BORDER_COLOR = '--app-border-color',
	CARD_BACKGROUND = '--card-background',
	CARD_BACKGROUND_FOR_NEW = '--card-background-for-new',
	TOGGLE_CHECKED = '--toggle-checked',
	DISABLED_CHECKBOX_BORDER_FRAME = '--disabled-checkbox-border-frame',
	DISABLED_CHECKMARK_PATH = '--disabled-checkmark-path',
	CHECKBOX_BORDER_FRAME = '--checkbox-border-frame',
	CHECKBOX_CHECKED_BACKGROUND = '--checkbox-checked-background',
	CHECKBOX_BACKGROUND = '--checkbox-background',
	BACKGROUND_NONE = '--background-none',
	STROKE_COLOR = '--stroke-color',
	TOGGLE_UNCHECKED = '--toggle-unchecked',
	TOGGLE_DISABLED = '--toggle-disable',
	DEEP_BACKGROUND = '--deep-background',
	RIPPLE_COLOR = '--ripple-color',
	PRIMARY_ACTIVE_COLOR = '--primary-active-color',
	PRIMARY_ACTIVE_TEXT_COLOR = '--primary-active-text-color',
	PRIMARY_TEXT_COLOR = '--primary-text-color',
	SEMI_BOLD_FONT_WEIGHT = '--semi-bold-font-weight',
	MEDIUM_FONT_WEIGHT = '--medium-font-weight',
	REGULAR_FONT_WEIGHT = '--regular-font-weight',
	SECONDARY_TEXT_COLOR = '--secondary-text-color',
	TERTIARY_TEXT_COLOR = '--tertiary-text-color',
	PRICE_UP_COLOR = '--price-up-color',
	PRICE_DOWN_COLOR = '--price-down-color',
	BADGE_TEXT_COLOR = '--badge-text-color',
	BADGE_SHORT_SELL_BACKGROUND = '--badge-short-sell-background',
	BADGE_BUY_BACKGROUND = '--badge-buy-background',
	BADGE_SELL_BACKGROUND = '--badge-sell-background',
	ACTIVE_STATUS_COLOR = '--active-status-color',
	INACTIVE_STATUS_COLOR = '--inactive-status-color',
	IN_POSITION_STATUS_COLOR = '--in-position-status-color',
	DRAFT_STATUS_COLOR = '--draft-status-color',
	EXECUTED_STATUS_COLOR = '--executed-status-color',
	EJECTED_STATUS_COLOR = '--ejected-status-color',
	REJECTED_STATUS_COLOR = '--rejected-status-color',
	CANCELLED_STATUS_COLOR = '--cancelled-status-color',
	TEMPLATE_STATUS_COLOR = '--template-status-color',
	STOCKS_GRADIENT = '--stocks-gradient',
	STOCKS_GRADIENT_STROKE = '--stocks-gradient-stroke',
	STOCKS_GRADIENT_FROM = '--stocks-gradient-from',
	STOCKS_GRADIENT_TO = '--stocks-gradient-to',
	FOREX_GRADIENT = '--forex-gradient',
	FOREX_GRADIENT_STROKE = '--forex-gradient-stroke',
	FOREX_GRADIENT_FROM = '--forex-gradient-from',
	FOREX_GRADIENT_TO = '--forex-gradient-to',
	CRYPTO_GRADIENT = '--crypto-gradient',
	CRYPTO_GRADIENT_STROKE = '--crypto-gradient-stroke',
	CRYPTO_GRADIENT_FROM = '--crypto-gradient-from',
	CRYPTO_GRADIENT_TO = '--crypto-gradient-to',
}
