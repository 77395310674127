import { IAlert } from '@levent/core';
import { AlertWizardState } from './alert-wizard-state';
import { AlertWizardStateModel } from './alert-wizard-state.model';
import { Selector } from '@ngxs/store';

export class AlertWizardSelectors {
	@Selector([AlertWizardState])
	public static pending(state: AlertWizardStateModel): boolean {
		return state.pending;
	}
	@Selector([AlertWizardState])
	public static alert(state: AlertWizardStateModel): Partial<IAlert> {
		return state.model;
	}
}
