import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ERoutes } from '@levent/core';
import { AuthGuard } from '@levent/core/guards/auth.guard';
import { PagesCanActivateChildGuard } from '@levent/core/guards/pages-can-activate-child.guard';
import { PreloadingStrategyService } from '@levent/core/services';
import { environment } from 'src/environments/environment';

const routes: Routes = [
	{
		path: ERoutes.AUTH,
		loadChildren: () => import(/* webpackChunkName: "page-auth" */ './pages/auth').then(m => m.AuthModule),
		canActivateChild: [AuthGuard],
	},
	{
		path: ERoutes.EMPTY,
		loadChildren: () => import(/* webpackChunkName: "pages" */ './pages').then(m => m.PagesModule),
		canActivateChild: [PagesCanActivateChildGuard],
	},
	{
		path: '**',
		redirectTo: `/${ERoutes.DASHBOARD}`,
	},
];

@NgModule({
	providers: [PreloadingStrategyService],
	imports: [
		RouterModule.forRoot(routes, {
			useHash: environment.USE_HASH,
			preloadingStrategy: PreloadingStrategyService,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
