import { AbstractControl, ValidatorFn } from '@angular/forms';

export class LevValidators {
	public static MinMaxLength(min: number = 1, max: number = 9): ValidatorFn {
		return (c: AbstractControl) => {
			let error = { errorCode: 1 };

			if (c.value) {
				error = null;

				let value: any = +c.value;

				value = value.toString();

				if (value.length < min || value.length > max) {
					value = +value.slice(0, value.length - 1);
					error = { errorCode: 2 };
				}

				if (c.value !== value) {
					c.setValue(value, { emitEvent: false });
				}
			}
			return error;
		};
	}

	public static MinMaxRange(
		min: number = 0,
		max: number = 100,
		canNegative: false,
		valueType: 'number' | 'string' = 'number',
	): ValidatorFn {
		return (c: AbstractControl) => {
			let error = { errorCode: 1 };
			if (c.value) {
				error = null;
				if ((c.value === '-' && canNegative) || c.value.toString().slice(-1) === '.') {
					return null;
				}
				let value: any = +c.value;
				const naN = isNaN(c.value);
				if (naN) {
					value = 0;
				}

				if (value < min) {
					value = min;
				}
				if (value > max) {
					value = max;
				}
				if (+c.value !== value) {
					if (valueType === 'string') {
						value = `${value}`;
					} else if (valueType === 'number') {
						value = +value;
					}
					c.patchValue(value, { emitEvent: false });
				}
			}
			return error;
		};
	}
}
