import { Injectable } from '@angular/core';
import { ChangeLangAction, CoreSelectors } from '@lev-store/core';
import { ELocalStorageKeys, ELanguages } from '@levent/core';
import { ThemeService } from '@lev-shared/app-theme';
import { Store } from '@ngxs/store';

@Injectable()
export class AppInitService {
	constructor(private store: Store, private themeService: ThemeService) {}

	public init(): Promise<void> {
		return new Promise<void>(resolve => {
			const lang = this.store.selectSnapshot(CoreSelectors.language) || ELanguages.EN;
			let activeTheme;
			try {
				const theme = localStorage.getItem(ELocalStorageKeys.CURRENT_THEME);
				activeTheme = this.themeService.getTheme(theme);
			} catch (e) {
				activeTheme = this.themeService.getActiveTheme();
			}
			this.themeService.updateTheme(activeTheme);
			this.store.dispatch(new ChangeLangAction(lang));
			resolve();
		});
	}
}

export function initializeApp(appInitService: AppInitService): any {
	return (): Promise<void> => {
		return appInitService.init();
	};
}
