import { CoreStateModel, CoreState } from '@lev-store/core';
import { IUserConfiguration } from '@levent/core/DTO/models/user-configuration';
import { Selector } from '@ngxs/store';
import { AuthState } from './auth-state';
import { AuthStateModel } from './auth-state.model';
import { IUser } from '@levent/core';

export class AuthSelectors {
	@Selector([AuthState, CoreState])
	public static pending(authState: AuthStateModel, coreState: CoreStateModel): boolean {
		return !coreState.pending ? authState.pending : false;
	}

	@Selector([AuthState]) public static token(state: AuthStateModel): string {
		return state.token;
	}

	@Selector([AuthState]) public static refreshToken(state: AuthStateModel): string {
		return state.refreshToken;
	}

	@Selector([AuthState]) public static expiresIn(state: AuthStateModel): number {
		return state.expiresIn;
	}

	@Selector([AuthState]) public static user(state: AuthStateModel): Partial<IUser> {
		return state.user;
	}

	@Selector([AuthState]) public static userConfigurationDetails(state: AuthStateModel): Partial<IUserConfiguration> {
		return state.userConfigurationDetails;
	}
}
