import { NgModule } from '@angular/core';
import { IconService } from '@levent/core/services';

@NgModule({
	providers: [IconService],
})
export class IconRegistryModule {
	constructor(private readonly iconService: IconService) {
		this.iconService.registerIcons();
		this.iconService.registerLogos();
	}
}
