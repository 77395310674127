import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ESvgIcons } from '@levent/core/enums/svg-icons.enum';

const ICONS: string = 'assets/icons';
const LOGO_URL: string = 'assets/images/logo';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

	public icons: string[] = Object.values(ESvgIcons);

	/**
	 * Method #registerIcons for registration icons from assets/icons
	 * If you want to add some new icon you should move the icon to directory assets/icons
	 * then add file name to enum ESvgIcons. And use from Enum in your component.
	 * For example:
	 *
	 * Move the file new-icon.svg to - assets/icons/new-icon.svg
	 *
	 * export enum ESvgIcons {
	 *     MY_NEW_ICON = 'new-icon',
	 * }
	 *
	 * @Component({
	 *     template: '
	 *          <mat-icon [svgIcon]="ICONS.MY_NEW_ICON"></mat-icon>
	 *     ',
	 * })
	 * export class SomeComponent {
	 *     public readonly ICONS = ESvgIcons;
	 * }
	 */
	public registerIcons(): void {
		this.matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
		for (const key of this.icons) {
			this.matIconRegistry.addSvgIcon(key, this.sanitizer.bypassSecurityTrustResourceUrl(`${ICONS}/${key}.svg`));
		}
	}

	public registerLogos(): void {
		[
			ESvgIcons.LOGO_FULL,
			ESvgIcons.LOGO_SHORT,
			ESvgIcons.LOGO_MOBILE,
			ESvgIcons.BLACK_LOGO,
			ESvgIcons.WHITE_LOGO,
		].forEach(key => {
			this.matIconRegistry.addSvgIcon(
				key,
				this.sanitizer.bypassSecurityTrustResourceUrl(`${LOGO_URL}/${key}.svg`),
			);
		});
	}
}
