export enum EBarInterval {
	OneMinute = 0,
	ThreeMinutes = 1,
	FiveMinutes = 2,
	FifteenMinutes = 3,
	ThirtyMinutes = 4,
	OneHour = 5,
	OneDay = 6,
	OneWeek = 7,
	OneMonth = 8,
	OneYear = 9,
	FiveYears = 10,
}
