import { Id, IInstrument } from '@levent/core';
import { IMarketState } from '@levent/core/DTO/models/market-state';

export class OnTickInstrumentAction {
	public static readonly type = '[Instruments-state] On tick instrument';
	// TODO: check this model
	constructor(public payload: any) {}
}

export class FetchMarketStateAction {
	public static readonly type = '[Instruments-state] fetch market state.';
}

export class FetchMarketStateSuccessAction {
	public static readonly type = '[Instruments-state] fetch market state success.';
	constructor(public payload: IMarketState) {}
}

export class FetchMarketStateErrorAction {
	public static readonly type = '[Instruments-state] fetch market state error.';
}

export class FetchMarketStateByDateAction {
	public static readonly type = '[Instruments-state] fetch market state by date range.';
	constructor(public payload: { from: string; to: string }) {}
}

export class FetchMarketStateByDateSuccessAction {
	public static readonly type = '[Instruments-state] fetch market state success.';
	constructor(public payload: { from: string; to: string; marketState: IMarketState }) {}
}

export class FetchMarketStateByDateErrorAction {
	public static readonly type = '[Instruments-state] fetch market state error.';
}

export class SearchInstrumentsAction {
	public static readonly type = '[Instruments-state] Search instrument by name or symbol';

	constructor(public searchStr?: string) {}
}

export class FetchInstrumentsAction {
	public static readonly type = '[Instruments-state] fetch instruments by filters';
	constructor(public payload: { searchStr?: string; index?: string; filters?: string; actionType?: string }) {}
}

export class FetchInstrumentsByIdAction {
	public static readonly type = '[Instruments-state] Fetch instrument by id.';
	constructor(public instrumentIds: Id[]) {}
}

export class UpdateInstrumentMapByIdAction {
	public static readonly type = '[Instruments-state] update instrument map by id';
	constructor(public instruments: IInstrument[]) {}
}

export class AddInstrumentInstanceInViewAction {
	public static readonly type = '[Instruments-state] Add instrument instance in view';

	constructor(public payload?: Id | Id[]) {}
}

export class RemoveInstrumentInstanceInViewAction {
	public static readonly type = '[Instruments-state] Removed instrument instance in view';

	constructor(public payload?: Id | Id[]) {}
}

export class ToggleFavouriteInstrumentAction {
	public static readonly type = '[Instruments-state] toggle favourite instrument.';
	constructor(public instrumentId: Id) {}
}

export class AddToFavouriteInstrumentAction {
	public static readonly type = '[Instruments-state] add favourite instrument.';
	constructor(public instrumentId: Id) {}
}

export class AddToFavouriteInstrumentSuccessAction {
	public static readonly type = '[Instruments-state] add favourite instrument success.';
	constructor(public instrumentId: Id) {}
}

export class AddToFavouriteInstrumentErrorAction {
	public static readonly type = '[Instruments-state] add favourite instrument error.';
}

export class RemoveFromFavouriteInstrumentAction {
	public static readonly type = '[Instruments-state] remove favourite instrument.';
	constructor(public instrumentId: Id) {}
}

export class RemoveFromFavouriteInstrumentSuccessAction {
	public static readonly type = '[Instruments-state] remove from favourite instrument success.';
	constructor(public instrumentId: Id) {}
}

export class RemoveFromFavouriteInstrumentErrorAction {
	public static readonly type = '[Instruments-state] remove from favourite instrument error.';
}
