import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ANIMATION_DURATION } from '@levent/core/animations';
import { EApi, IBrokerInfo, IPerson, RightSidebar } from '@levent/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { catchError, Observable, throwError } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { defaults, PagesStateModel } from './pages-state.model';

import {
	AddBreadcrumbAction,
	FetchBrokerInfoAction,
	FetchUserInfoAction,
	SetRightSidebarAction,
	UpdateBreadcrumbsAction,
	UpdateUserInfoAction,
} from './pages.actions';

@State<PagesStateModel>({
	name: 'pagesState',
	defaults,
})
@Injectable()
export class PagesState {
	constructor(private readonly ngZone: NgZone, private readonly httpClient: HttpClient) {}

	@Action(SetRightSidebarAction)
	public setRightSidebar(
		{ setState, getState }: StateContext<PagesStateModel>,
		{ payload, closeAll }: SetRightSidebarAction,
	): Observable<void> {
		const { rightSidebars } = getState();
		let newRightSidebars = [...rightSidebars];
		const sidebar = new RightSidebar(payload);
		const shouldOpen = sidebar.opened;
		let lastSidebar = newRightSidebars[newRightSidebars.length - 1];
		if (lastSidebar) {
			setState(
				patch({
					rightSidebar: patch<RightSidebar>({
						opened: false,
						name: '',
						hasBackdrop: false,
						mode: 'over',
					}),
				}),
			);
		}
		if (shouldOpen) {
			if (!lastSidebar || lastSidebar.name !== sidebar.name) {
				newRightSidebars.push(sidebar);
			}
		} else {
			let indexSidebar: number = null;
			for (let sidebarIndex = 0; sidebarIndex < newRightSidebars.length; sidebarIndex++) {
				if (newRightSidebars[sidebarIndex].name === sidebar.name) {
					indexSidebar = sidebarIndex;
				}
			}
			if (indexSidebar !== null) {
				newRightSidebars.splice(indexSidebar, 1);
			} else {
				newRightSidebars.pop();
			}
		}
		if (closeAll) {
			setState(patch({ rightSidebars: [] as RightSidebar[], rightSidebar: new RightSidebar() }));
		}
		return this.ngZone.onStable.pipe(
			take(1),
			delay(ANIMATION_DURATION),
			tap(() => {
				if (!closeAll) {
					lastSidebar = newRightSidebars[newRightSidebars.length - 1];
					setState(patch({ rightSidebars: newRightSidebars, rightSidebar: lastSidebar ?? sidebar }));
				}
			}),
		);
	}

	@Action(AddBreadcrumbAction, { cancelUncompleted: true })
	public addBreadcrumb(
		{ patchState, getState }: StateContext<PagesStateModel>,
		{ breadcrumb }: AddBreadcrumbAction,
	): void {
		const { breadcrumbs } = getState();
		patchState({ breadcrumbs: [...breadcrumbs, breadcrumb] });
	}

	@Action(UpdateBreadcrumbsAction, { cancelUncompleted: true })
	public updateBreadcrumbs(
		{ patchState }: StateContext<PagesStateModel>,
		{ breadcrumbs }: UpdateBreadcrumbsAction,
	): void {
		patchState({ breadcrumbs });
	}

	@Action(FetchUserInfoAction)
	public fetchUserInfo({ dispatch }: StateContext<PagesStateModel>): Observable<IPerson> {
		return this.httpClient.get<IPerson>('').pipe(
			tap(person => {
				dispatch(new UpdateUserInfoAction(person));
			}),
			catchError(error => {
				return throwError(() => error);
			}),
		);
	}

	@Action(UpdateUserInfoAction)
	public updateUserInfo({ patchState }: StateContext<PagesStateModel>, { userInfo }: UpdateUserInfoAction): void {
		patchState({ userInfo });
	}

	@Action(FetchBrokerInfoAction)
	public fetchBrokerInfo({ patchState }: StateContext<PagesStateModel>): Observable<IBrokerInfo> {
		return this.httpClient.get<IBrokerInfo>(EApi.BROKER_INFO).pipe(
			tap(brokerInfo => {
				patchState({ brokerInfo });
			}),
			catchError(error => {
				patchState({ brokerInfo: defaults.brokerInfo });
				return throwError(() => error);
			}),
		);
	}
}
