import { EMarketState, Id, IInstrumentMapById } from '@levent/core';
import { IMarketState } from '@levent/core/DTO/models/market-state';

export type InstrumentInstanceInView = { [key: Id]: number };

export class InstrumentsStateModel {
	constructor(
		public pending: boolean = false,
		public currentMarketState: IMarketState = {
			state: EMarketState.Premarket,
			dateTime: new Date().toISOString(),
			nextState: EMarketState.Open,
			nextChange: new Date().toISOString(),
			description: '',
		},
		public marketStateMap: { [key: string]: IMarketState } = {},
		public instrumentMapById: IInstrumentMapById = {},
		public instrumentIds: Id[] = [],
		public instrumentInstanceInView: InstrumentInstanceInView = {},
		public favouriteInstrumentIds: Id[] = [],
	) {}
}

export const defaults: InstrumentsStateModel = new InstrumentsStateModel();
