import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let { url } = request;
		const isAssets = url.includes('assets');
		const isNotGateway = url.startsWith('http');
		url =
			isAssets || isNotGateway
				? url
				: `${environment.SERVICES.GATEWAY.PATH}/${environment.API_VERSION}/${request.url}`;
		const apiReq = request.clone({ url });
		return next.handle(apiReq);
	}
}
