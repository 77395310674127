import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FetchInstrumentsByIdAction } from '@lev-store/shared/instruments';
import { EApi, ERoutes, Id } from '@levent/core';
import { ANIMATION_DURATION } from '@levent/core/animations';
import { AuthSkipInterceptor } from '@levent/core/constants/interceptor-skips';
import { IUserConfiguration } from '@levent/core/DTO/models/user-configuration';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
	LoginAction,
	LogoutAction,
	LoginWithRefreshTokenAction,
	SuccessLoginAction,
	ErrorLoginAction,
	RegistrationAction,
	ConfirmRegistrationAction,
	ErrorRegistrationAction,
	FetchUserConfigurationDetailsAction,
	FetchUserConfigurationDetailsSuccessAction,
	FetchUserConfigurationDetailsErrorAction,
} from './auth.actions';
import { Router } from '@angular/router';
import { AuthStateModel, defaults } from './auth-state.model';

interface TokenResponse {
	id_token: string;
	access_token: string;
	expires_in: number;
	token_type: 'Bearer';
	refresh_token: string;
	scope: string;
}

@State<AuthStateModel>({
	name: 'auth',
	defaults,
})
@Injectable()
export class AuthState {
	constructor(private readonly httpClient: HttpClient, protected readonly router: Router) {}

	@Action(RegistrationAction) public register(
		{ dispatch }: StateContext<AuthStateModel>,
		{ username, password, firstName, lastName }: RegistrationAction,
	): Observable<void> {
		return this.httpClient
			.post<void>(`${environment.SERVICES.IDENTITY.PATH}/${environment.API_VERSION}/account/register`, {
				username,
				password,
				firstName,
				lastName,
			})
			.pipe(catchError(() => dispatch(new ErrorRegistrationAction())));
	}

	@Action(ConfirmRegistrationAction) public confirmRegistration(
		{ dispatch }: StateContext<AuthStateModel>,
		{ username, password, code }: ConfirmRegistrationAction,
	): Observable<void> {
		return this.httpClient
			.post<void>(`${environment.SERVICES.IDENTITY.PATH}/${environment.API_VERSION}/account/confirm`, {
				username,
				password,
				code,
			})
			.pipe(
				tap(() => {
					dispatch(new LoginAction({ username, password }));
				}),
				catchError(() => dispatch(new ErrorRegistrationAction())),
			);
	}

	@Action(LoginAction) public login(
		{ dispatch, patchState }: StateContext<AuthStateModel>,
		{ credentials }: LoginAction,
	): Observable<TokenResponse | void> {
		patchState({ pending: true });
		return this.httpClient
			.post<TokenResponse>(
				`${environment.SERVICES.IDENTITY.PATH}/${environment.API_VERSION}/account/login`,
				credentials,
			)
			.pipe(
				delay(ANIMATION_DURATION * 1.5),
				tap((resp: TokenResponse) => {
					const { access_token, refresh_token, expires_in } = resp;
					dispatch(new SuccessLoginAction(access_token, refresh_token, expires_in));
					this.router.navigateByUrl(ERoutes.EMPTY)?.then(() => {
						patchState({ pending: false });
					});
				}),
				catchError(() => {
					patchState({ pending: false });
					return dispatch(new ErrorLoginAction(true));
				}),
			);
	}

	@Action(LoginWithRefreshTokenAction) public loginWithRefreshToken({
		getState,
		dispatch,
	}: StateContext<AuthStateModel>): Observable<TokenResponse | void> {
		const { refreshToken } = getState();
		const options = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				[AuthSkipInterceptor]: AuthSkipInterceptor,
			},
		};
		const body = new URLSearchParams();
		body.append('refresh_token', refreshToken);
		body.append('client_id', 'ro.client');
		body.append('client_secret', 'secret');
		body.append('grant_type', 'refresh_token');
		return this.httpClient
			.post<TokenResponse>(`${environment.SERVICES.IDENTITY.PATH}/connect/token`, body, options)
			.pipe(
				tap((resp: TokenResponse) => {
					const { access_token, refresh_token, expires_in } = resp;
					dispatch(new SuccessLoginAction(access_token, refresh_token, expires_in));
				}),
				catchError(() => dispatch(new ErrorLoginAction())),
			);
	}

	@Action(SuccessLoginAction) public successLogin(
		{ patchState }: StateContext<AuthStateModel>,
		{ token, refreshToken, expiresIn }: SuccessLoginAction,
	): void {
		patchState({ token, refreshToken, expiresIn });
	}

	@Action(ErrorLoginAction) public errorLogin(
		{ dispatch }: StateContext<AuthStateModel>,
		{ incorrectLoginOrPassword }: ErrorLoginAction,
	): void {
		if (!incorrectLoginOrPassword) {
			dispatch(new LogoutAction());
		}
	}

	@Action(LogoutAction) public logout({ patchState }: StateContext<AuthStateModel>): void {
		patchState({ token: null, refreshToken: null, user: {} });
		this.router.navigateByUrl(`${ERoutes.AUTH}/${ERoutes.LOGIN}`)?.then(() => {
			// location.reload();
		});
	}

	@Action(FetchUserConfigurationDetailsAction) public fetchUserConfigurationDetails({
		dispatch,
	}: StateContext<AuthStateModel>): Observable<IUserConfiguration> {
		return this.httpClient.get<IUserConfiguration>(EApi.USER_CONFIGURATIONS_DETAILS).pipe(
			tap((resp: IUserConfiguration) => dispatch(new FetchUserConfigurationDetailsSuccessAction(resp))),
			catchError(error => {
				dispatch(new FetchUserConfigurationDetailsErrorAction());
				return throwError(() => error);
			}),
		);
	}

	@Action(FetchUserConfigurationDetailsSuccessAction) public fetchUserConfigurationDetailsSuccess(
		{ patchState, dispatch }: StateContext<AuthStateModel>,
		{ payload }: FetchUserConfigurationDetailsSuccessAction,
	): void {
		patchState({ userConfigurationDetails: payload });
		const instrumentsIds = payload.instruments.map(instrument => instrument.instrumentId);
		dispatch([new FetchInstrumentsByIdAction(instrumentsIds)]);
	}
}
