import { Direction } from '@angular/cdk/bidi';
import { ELanguages } from '@levent/core';
import { Selector } from '@ngxs/store';
import { CoreState } from './core-state';
import { CoreStateModel } from './core-state.model';

export class CoreSelectors {
	@Selector([CoreState])
	public static pending(state: CoreStateModel): boolean {
		return state.pending;
	}

	@Selector([CoreState])
	public static appProgress(state: CoreStateModel): number {
		return state.appProgress;
	}

	@Selector([CoreState])
	public static dir(state: CoreStateModel): Direction {
		return state.dir;
	}

	@Selector([CoreState])
	public static language(state: CoreStateModel): ELanguages {
		return state.language;
	}

	@Selector([CoreState])
	public static settings(state: CoreStateModel): any {
		return state.settings;
	}

	@Selector([CoreState])
	public static isMobileView(state: CoreStateModel): boolean {
		return state.isMobileView;
	}

	@Selector([CoreState])
	public static isTabletView(state: CoreStateModel): boolean {
		return state.isTabletView;
	}

	@Selector([CoreState])
	public static isExpandedView(state: CoreStateModel): boolean {
		return !state.isTabletView && !state.isMobileView;
	}
}
