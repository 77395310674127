import { FormControl, Validators } from '@angular/forms';
import { EConditionType, EOrderType, IComplexCondition, IUICondition, LevValidators } from '@levent/core';
import { DefaultUIComplexCondition } from './default-ui-complex-condition.model';
import { IUIStrategyComplexCondition } from './ui-strategy-complex-condition.interface';

const defaultMaxQuantity = 10 * 10000000;
export class UIStrategyComplexCondition<T extends IComplexCondition>
	extends DefaultUIComplexCondition<T>
	implements IUIStrategyComplexCondition<T>
{
	private tradeInOptionsMap: any = { $: '$', Units: 'COMMON.UNITS' };
	private maxQuantity: number = defaultMaxQuantity;
	public hasEntryConditions: boolean = false;
	public hasExitConditions: boolean = false;
	public amountControl: FormControl = new FormControl(null, LevValidators.MinMaxRange(1, 1000 * 1000 * 10, false));
	public tradeInControl: FormControl = new FormControl('Units');
	public tradeInOptions: any[] = Object.keys(this.tradeInOptionsMap).map(key => ({
		value: key,
		label: this.tradeInOptionsMap[key],
	}));

	public get tradeInValue(): 'Units' | '$' {
		return this.tradeInControl.value;
	}

	public get tradeInLabel(): string {
		return this.tradeInOptionsMap[this.tradeInValue];
	}

	public get amountValue(): number {
		return this.amountControl.value;
	}

	public get quantity(): number {
		return this.conditionFormGroup.controls['quantity'].value;
	}

	public get buySell(): EOrderType {
		return this.conditionFormGroup.controls['buySell'].value ?? undefined;
	}

	constructor(props: Partial<T & { quantity: number; buySell: EOrderType }>) {
		super(props);
		this.buildConditionFormGroup(true);
		this.conditionFormGroup.addControl('buySell', new FormControl(props.buySell, [Validators.required]));
		this.conditionFormGroup.addControl('quantity', new FormControl(0, [Validators.required, Validators.min(1)]));
		this.patchValue(props);
	}

	public setBySell(buySell: EOrderType, instrumentPrice: number, quantity: number): void {
		if (this.buySell !== buySell) {
			this.patchValue({ buySell } as any);
			this.updateAndValidityAmountByOrderType(instrumentPrice, quantity);
		}
	}

	public updateAndValidityAmountByOrderType(instrumentPrice: number, quantity: number): void {
		if (this.buySell === EOrderType.Buy) {
			this.tradeInOptions = Object.keys(this.tradeInOptionsMap).map(key => ({
				value: key,
				label: this.tradeInOptionsMap[key],
			}));
			this.maxQuantity = defaultMaxQuantity;
		} else if (this.buySell === EOrderType.Sell) {
			this.tradeInOptions = [{ value: 'Units', label: 'COMMON.UNITS' }];
			this.maxQuantity = quantity;
			this.tradeInControl.setValue('Units');
		}
		this.validateQuantity(instrumentPrice);
	}

	public validateQuantity(instrumentPrice: number): void {
		const isTradeInUtils = this.tradeInValue !== '$';
		let quantity = this.quantity;
		if (isTradeInUtils) {
			quantity = +this.amountValue;
			if (quantity > this.maxQuantity) {
				quantity = this.maxQuantity;
				this.amountControl.setValue(this.maxQuantity);
			}
		} else if (instrumentPrice ?? false) {
			quantity = Math.trunc(this.amountValue / instrumentPrice);
		}

		this.patchValue({ quantity } as any);
	}

	public override addUICondition(value: IUICondition): void {
		super.addUICondition(value);
		this.checkExistConditions();
	}

	public override removeUICondition(value: IUICondition): void {
		super.removeUICondition(value);
		this.checkExistConditions();
	}

	private checkExistConditions(): void {
		this.hasEntryConditions = false;
		this.hasExitConditions = false;
		for (const uiCondition of this.conditions) {
			const conditionType = uiCondition.conditionType;
			if (uiCondition.valid) {
				if (!this.hasExitConditions) this.hasExitConditions = conditionType === EConditionType.Entry;
				if (!this.hasExitConditions) this.hasExitConditions = conditionType === EConditionType.Exit;
			}
			if (this.hasEntryConditions && this.hasExitConditions) {
				break;
			}
		}
	}
}
