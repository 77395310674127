/**
 * Action to connect to the websocket. Optionally pass a URL.
 */
import { EConnectionNames, SignalROptions } from './signalR-state.model';
import {
	AbortError,
	AggregateErrors,
	DisabledTransportError,
	FailedToNegotiateWithServerError,
	FailedToStartTransportError,
	HttpError,
	TimeoutError,
	UnsupportedTransportError,
} from '@microsoft/signalr/dist/esm/Errors';

export class ConnectSignalRAction {
	public static readonly type = '[SignalR-state] Connect.';
	constructor(public payload?: SignalROptions) {}
}

/**
 * Action to disconnect the SignalR.
 */
export class DisconnectSignalRAction {
	public static type = '[SignalR-state] Disconnect.';
	constructor(public payload?: EConnectionNames) {}
}

/**
 * Action to invoke to the server.
 */
export class InvokeSignalRMessageAction {
	public static type = '[SignalR-state] Invoke Message.';
	constructor(public connectionName: EConnectionNames, public name: string, public payload?: unknown) {}
}

/**
 * Action to send to the server.
 */
export class SendSignalRMessageAction {
	public static type = '[SignalR-state] Send Message.';
	constructor(public connectionName: EConnectionNames, public name: string, public payload?: unknown) {}
}

/**
 * Action triggered when SignalR is connected
 */
export class SignalRConnectedAction {
	public static type = '[SignalR-state] Connected.';
	constructor(public connectionName: EConnectionNames) {}
}

/**
 * Action triggered when SignalR is disconnected
 */
export class SignalRDisconnectedAction {
	public static type = '[SignalR-state] Disconnected.';
	constructor(public connectionName: EConnectionNames, public payload?: Error | undefined) {}
}

/**
 * Action triggered when a error ocurrs
 */
export class SignalRMessageErrorAction {
	public static type = '[SignalR-state] Message Error.';
	constructor(
		public connectionName: EConnectionNames,
		public payload?:
			| FailedToNegotiateWithServerError
			| AggregateErrors
			| FailedToStartTransportError
			| DisabledTransportError
			| UnsupportedTransportError
			| AbortError
			| TimeoutError
			| HttpError
			| any,
	) {}
}

/**
 * Action triggered when SignalR is connected
 */
export class SignalRReconnectedAction {
	public static type = '[SignalR-state] Reconnected.';
	constructor(public connectionName: EConnectionNames, public connectionId?: string) {}
}

/**
 * Action triggered when SignalR is connected
 */
export class SignalRReconnectingAction {
	public static type = '[SignalR-state] Reconnecting.';
	constructor(public connectionName: EConnectionNames, public payload?: Error) {}
}

/**
 * Action triggered when SignalR is connected
 */
export class SignalRStreamCompletedAction {
	public static type = '[SignalR-state] Stream Completed.';
	constructor(public connectionName: EConnectionNames) {}
}

/**
 * Action triggered when a error ocurrs
 */
export class SignalRStreamErrorAction {
	public static type = '[SignalR-state] Stream Error.';
	constructor(public connectionName: EConnectionNames, public payload?: Error) {}
}

/**
 * Action to stream from the server.
 */
export class StreamSignalRMessageAction {
	public static type = '[SignalR-state] Stream Message.';
	constructor(public connectionName: EConnectionNames, public name: string, public payload: unknown) {}
}
