export enum EStrategyStatus {
	Unknown = 'Unknown',
	Inactive = 'Inactive',
	Active = 'Active',
	InPosition = 'InPosition',
	Executed = 'Completed',
	Ejected = 'Ejected',
	Rejected = 'Rejected',
	Cancelled = 'Cancelled',
	ActivateRequested = 'ActivateRequested',
	CancelRequested = 'CancelRequested',
	EjectRequested = 'EjectRequested',
	// TODO: this statuses for demo
	Draft = 'Draft',
}
