import { Injectable } from '@angular/core';

import { catchError, Observable, ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class QueueInterceptor implements HttpInterceptor {
	private requestQueue: ReplaySubject<unknown>[] = [];
	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let req = next.handle(request);
		if (!request.url.includes('assets')) {
			const requestQueueItem$ = new ReplaySubject<unknown>(1);
			const result$ = requestQueueItem$.pipe(
				switchMap(() =>
					next.handle(request).pipe(
						tap(response => {
							if (response.type == HttpEventType.Response) {
								this.processNextRequest();
							}
						}),
						catchError(err => {
							this.processNextRequest();
							throw err;
						}),
					),
				),
			);
			if (request.url.includes(`${environment.SERVICES.IDENTITY.PATH}/connect/token`)) {
				this.requestQueue.unshift(requestQueueItem$);
				this.dispatchRequest();
			} else {
				this.requestQueue.push(requestQueueItem$);
			}
			if (this.requestQueue.length <= 1) {
				this.dispatchRequest();
			}
			req = result$;
		}

		return req;
	}

	private processNextRequest(): void {
		if (this.requestQueue.length > 0) {
			this.requestQueue.shift();
		}
		this.dispatchRequest();
	}

	private dispatchRequest(): void {
		if (this.requestQueue.length > 0) {
			const nextSub$ = this.requestQueue[0];
			nextSub$.next(null);
			nextSub$.complete();
		}
	}
}
