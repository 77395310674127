export enum ERoutes {
	EMPTY = '',
	REGISTRATION = 'registration',
	AUTH = 'auth',
	LOGIN = 'login',
	DASHBOARD = 'dashboard',
	MARKET = 'market',
	STRATEGIES = 'strategies',
	STRATEGY_CREATE = 'strategies-creation',
	STRATEGY_EDIT = 'strategy',
	STRATEGY_LIBRARY = 'strategy-library',
	ALERTS = 'alerts',
	ALERT_CREATE = 'alert-creation',
	ALERT_EDIT = 'alert',
	PROFILE = 'profile',
	BROKER = 'broker',
	PROFILE_INFO = 'info',
}
