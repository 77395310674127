import { Id, IStrategy, ITemplate } from '@levent/core';

export class CreateStrategyByInstrumentIdAction {
	public static readonly type = '[Strategy-Wizard] create strategies by instrument id.';
	constructor(public instrumentId: Id) {}
}

export class CreateStrategyByTemplateAction {
	public static readonly type = '[Strategy-Wizard] create strategies template.';
	constructor(public template: ITemplate) {}
}

export class UpdateStrategyAction {
	public static readonly type = '[Strategy-Wizard] update strategies model.';
	constructor(public strategy: Partial<IStrategy>) {}
}

export class ActivateStrategyAction {
	public static readonly type = '[Strategy-Wizard] save and active strategies on back end.';
}

export class UpdateWizardStrategyAction {
	public static readonly type = '[Strategy-Wizard] update strategies from wizard on back end.';
	constructor(public payload: Partial<IStrategy>) {}
}

export class CreateWizardStrategyAction {
	public static readonly type = '[Strategy-Wizard] create strategies from wizard on back end.';
	constructor(public payload: Partial<IStrategy>) {}
}
