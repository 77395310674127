import { IBreadcrumb, IBrokerInfo, IPerson } from '@levent/core';
import { RightSidebar } from '@levent/core/models/right-sidebar.model';

export class PagesStateModel {
	constructor(
		public pending: boolean = false,
		public userInfo: IPerson = {
			firstName: 'Test firstName',
			lastName: 'Test lastName',
			middleName: 'Test middleName',
			phone: '132456789',
			phonePrefix: '+154',
			address: 'test address',
			houseNumber: '9',
			city: 'Test city',
			email: 'test@email.com',
			state: 'Test state',
			zip: '911',
			dateOfBirth: new Date().toISOString(),
		},
		public brokerInfo: IBrokerInfo = {
			availableCash: 0,
			totalBalance: 0,
			position: [],
		},
		public rightSidebar: RightSidebar = new RightSidebar(),
		public rightSidebars: RightSidebar[] = [],
		public breadcrumbs: IBreadcrumb[] = [],
		public strategyHistory: any = {},
	) {}
}

export const defaults: PagesStateModel = new PagesStateModel();
