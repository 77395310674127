import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	ECalculationType,
	ECompareType,
	EConditionType,
	EIndicatorType,
	ICondition,
	LevValidators,
} from '@levent/core';
import { IUICondition } from './ui-condition.interface';

export abstract class DefaultUICondition<T extends ICondition> implements IUICondition<T> {
	public name: string;
	public label: string;
	public tooltipText: string;
	public parametersType: {
		parameter: ECompareType | ECalculationType;
		label: string;
	}[] = [];
	public expanded: boolean = false;
	public conditionFormGroup: FormGroup;
	public get indicatorType(): EIndicatorType {
		return this.conditionFormGroup.get('rightExpression.indicatorType').value;
	}
	public get conditionType(): EConditionType {
		return this.conditionFormGroup.get('conditionType').value;
	}
	public get value(): T {
		return this.conditionFormGroup.getRawValue();
	}
	public get valid(): boolean {
		return this.conditionFormGroup.valid;
	}
	protected constructor() {
		this.buildConditionFormGroup();
	}

	public buildConditionFormGroup(): void {
		this.conditionFormGroup = new FormGroup({
			instrumentId: new FormControl(null, [Validators.required]),
			conditionType: new FormControl(null, [Validators.required]),
			leftExpression: new FormGroup({
				indicatorType: new FormControl(null, [Validators.required]),
				parameters: new FormGroup({}),
			}),
			rightExpression: this.getRightExpressionFormGroup(),
		});
	}

	public getRightExpressionFormGroup(): FormGroup {
		return new FormGroup({
			indicatorType: new FormControl(null, [Validators.required]),
			parameters: new FormGroup({
				offset: new FormControl(null, [
					Validators.required,
					LevValidators.MinMaxRange(1, 1000 * 1000, false, 'string'),
				]),
				calculationType: new FormControl(ECalculationType.Number),
			}),
		});
	}

	public toggleExpand(value: boolean): void {
		this.expanded = value;
	}

	public getConditionId(): string {
		const formValue = this.conditionFormGroup.getRawValue();
		const keys = [
			formValue.instrumentId,
			formValue.conditionType,
			!!formValue.isBenchmark,
			formValue.leftExpression.indicatorType,
			formValue.rightExpression.indicatorType,
		];
		return keys.join('_');
	}

	public patchValue(value: Partial<T>): void {
		this.conditionFormGroup.patchValue(value, { emitEvent: false });
	}

	public updateDependenciesAndValidity(): void {}
}
