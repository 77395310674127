import { Direction } from '@angular/cdk/bidi';
import { ELanguages } from '@levent/core/enums/languages.enum';

export class CoreStateModel {
	constructor(
		public pending: boolean = false,
		public isMobileView: boolean = false,
		public isTabletView: boolean = false,
		public settings: any = {},
		public appProgress: number = 1,
		public dir: Direction = document.dir !== 'rtl' ? 'ltr' : 'rtl',
		public language: ELanguages = ELanguages.EN,
	) {}
}

export const defaults: CoreStateModel = new CoreStateModel();
