import { IBreadcrumb, IPerson, RightSidebar } from '@levent/core';

export class SetRightSidebarAction {
	public static readonly type = '[Pages-state] Set Right Sidebar';

	constructor(public payload?: RightSidebar, public closeAll?: boolean) {}
}

export class AddBreadcrumbAction {
	public static readonly type = '[Pages-state] add breadcrumbs.';

	constructor(public breadcrumb: IBreadcrumb) {}
}

export class UpdateBreadcrumbsAction {
	public static readonly type = '[Pages-state] update list of breadcrumbs.';

	constructor(public breadcrumbs: IBreadcrumb[]) {}
}

export class FetchUserInfoAction {
	public static readonly type = '[Pages-state] fetch user info.';
}

export class FetchBrokerInfoAction {
	public static readonly type = '[Pages-state] fetch broker info.';
}

export class UpdateUserInfoAction {
	public static readonly type = '[Pages-state] update user info.';

	constructor(public userInfo: IPerson) {}
}
