import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreSelectors, SetViewStateAction } from '@lev-store/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare const BUILD_VERSION: string;

@Component({
	selector: 'lev-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		private breakpointObserver: BreakpointObserver,
		private store: Store,
		private translateService: TranslateService,
	) {}

	public ngOnInit(): void {
		console.log('[build-version]:', BUILD_VERSION);
		this.breakpointObserver
			.observe([Breakpoints.XSmall, Breakpoints.Medium, Breakpoints.Small])
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: BreakpointState) => {
				this.store.dispatch(new SetViewStateAction(result));
			});
		this.store
			.select(CoreSelectors.language)
			.pipe(takeUntil(this.destroy$))
			.subscribe(lang => {
				this.translateService.use(lang);
			});
		// test-branch
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
