export enum EIndicator {
	RSI = 'RSI',
	VWAP = 'VWAP',
	MACD = 'MACD',
	BollingerBands = 'BollingerBands',
	SMA = 'SMA',
	SMMA = 'SMMA',
	StdDev = 'StdDev',
	EMA = 'EMA',
}

export enum EIndicatorBarInterval {
	OneMinute = 'OneMinute',
	ThreeMinutes = 'ThreeMinutes',
	FiveMinutes = 'FiveMinutes',
	FifteenMinutes = 'FifteenMinutes',
	ThirtyMinutes = 'ThirtyMinutes',
	OneHour = 'OneHour',
	OneDay = 'OneDay',
	OneWeek = 'OneWeek',
	OneMonth = 'OneMonth',
	OneYear = 'OneYear',
	FiveYears = 'FiveYears',
}

export enum EIndicatorComparerType {
	Static = 'Static',
	Dynamic = 'Dynamic',
}

export enum EIndicatorSource {
	Open = 'Open',
	Close = 'Close',
	High = 'High',
	Low = 'Low',
}

export const INDICATOR_LABEL_MAP: Record<EIndicator, { shortLabel: string; label: string }> = {
	[EIndicator.RSI]: {
		shortLabel: 'RSI',
		label: 'Relative Strength Index',
	},
	[EIndicator.VWAP]: {
		shortLabel: 'VWAP',
		label: 'VWAP',
	},
	[EIndicator.MACD]: {
		shortLabel: 'MACD',
		label: 'MACD',
	},
	[EIndicator.BollingerBands]: {
		shortLabel: 'BULL',
		label: 'Bollinger Bands',
	},
	[EIndicator.SMA]: {
		shortLabel: 'SMA',
		label: 'Simple Moving Average',
	},
	[EIndicator.SMMA]: {
		shortLabel: 'SMMA',
		label: 'SMMA',
	},
	[EIndicator.StdDev]: {
		shortLabel: 'StdDev',
		label: 'Standard Deviation',
	},
	[EIndicator.EMA]: {
		shortLabel: 'EMA',
		label: 'EMA',
	},
};

export const INDICATOR_BAR_INTERVAL_LABEL_MAP: Record<EIndicatorBarInterval, { shortLabel: string; label: string }> = {
	[EIndicatorBarInterval.OneMinute]: { shortLabel: '1', label: '1m' },
	[EIndicatorBarInterval.ThreeMinutes]: { shortLabel: '3', label: '3m' },
	[EIndicatorBarInterval.FiveMinutes]: { shortLabel: '5', label: '5m' },
	[EIndicatorBarInterval.FifteenMinutes]: { shortLabel: '15', label: '15m' },
	[EIndicatorBarInterval.ThirtyMinutes]: { shortLabel: '30', label: '30m' },
	[EIndicatorBarInterval.OneHour]: { shortLabel: '1h', label: '1h' },
	[EIndicatorBarInterval.OneDay]: { shortLabel: '1D', label: '1D' },
	[EIndicatorBarInterval.OneWeek]: { shortLabel: '1W', label: '1W' },
	[EIndicatorBarInterval.OneMonth]: { shortLabel: '1M', label: '1M' },
	[EIndicatorBarInterval.OneYear]: { shortLabel: '1Y', label: '1Y' },
	[EIndicatorBarInterval.FiveYears]: { shortLabel: '5Y', label: '5Y' },
};
