import { IAlert, Id } from '@levent/core';

export class CreateAlertByInstrumentIdAction {
	public static readonly type = '[AlertWizard-state] Create new alert by instrument id.';

	constructor(public instrumentId: Id) {}
}

export class UpdateAlertAction {
	public static readonly type = '[AlertWizard-state] update alert model alert.';

	constructor(public alert: Partial<IAlert>) {}
}

export class ActivateAlertAction {
	public static readonly type = '[AlertWizard-state] save and active Alert on back end.';
}

export class UpdateWizardAlertAction {
	public static readonly type = '[AlertWizard-state] update Alert from wizard on back end.';
	constructor(public payload: Partial<IAlert>) {}
}

export class CreateWizardAlertAction {
	public static readonly type = '[AlertWizard-state] create Alert from wizard on back end.';
	constructor(public payload: Partial<IAlert>) {}
}
