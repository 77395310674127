import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { EThemeProperties } from '@levent/core/enums/theme-properties.enum';
import { ACTIVE_THEME, Theme, THEMES } from './symbols';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	public stylesRef: HTMLStyleElement | null = null;
	public buildStylesRef(): void {
		if (this.stylesRef) {
			this.document.head.removeChild(this.stylesRef);
		}
		const style = document.createElement('style');
		style.appendChild(document.createTextNode(''));
		this.document.head.appendChild(style);
		this.stylesRef = style;
	}

	public themeChange: EventEmitter<Theme> = new EventEmitter<Theme>();

	constructor(
		@Inject(THEMES) private themes: Theme[],
		@Inject(ACTIVE_THEME) private theme: string,
		@Inject(DOCUMENT) private document: Document,
	) {}

	public getTheme(name: string): Theme {
		const theme = this.themes.find(t => t.name === name);
		if (!theme) {
			throw new Error(`Theme not found: '${name}'`);
		}
		return theme;
	}

	public getActiveTheme(): Theme | null {
		let theme: string = this.themes[0].name;
		if (this.theme) {
			theme = this.theme;
		}
		return this.getTheme(theme);
	}

	public getProperty(propName: typeof EThemeProperties[keyof typeof EThemeProperties]): string | number {
		return this.getActiveTheme().properties[propName];
	}

	public setTheme(name: string): void {
		this.theme = name;
		this.themeChange.emit(this.getActiveTheme());
	}

	public registerTheme(theme: Theme): void {
		this.themes.push(theme);
	}

	public updateThemeProperties(name: string, properties: { [key: string]: string }): void {
		const theme = this.getTheme(name);
		theme.properties = {
			...theme.properties,
			...properties,
		};

		if (name === this.theme) {
			this.themeChange.emit(theme);
		}
		this.updateTheme(theme);
	}

	public updateTheme(theme: Theme): void {
		this.buildStylesRef();
		let styleStr = ':root{';
		for (const key in theme.properties) {
			styleStr += `${key}: ${theme.properties[key as typeof EThemeProperties[keyof typeof EThemeProperties]]};`;
		}
		styleStr += '}';
		this.stylesRef.sheet.insertRule(styleStr);

		// remove old theme
		for (const name of this.theme) {
			this.document.body.classList.remove(`${name}-theme`);
		}

		// alias element with theme name
		this.document.body.classList.add(`${theme.name}-theme`);
	}
}
