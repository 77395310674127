import { InjectionToken } from '@angular/core';
import { ENotificationState } from '../enums';
import { Id } from './id';

export interface INotification {
	id: Id;
	entityId: Id;
	entityType: 'ALERT' | 'STRATEGY';
	state: ENotificationState;
	body: string;
	group: string;
	title: string;
	date: string | Date;
}

export const LEV_NOTIFICATION_TIP_DATA: InjectionToken<Partial<INotification>> = new InjectionToken<
	Partial<INotification>
>('NotificationTipData');
