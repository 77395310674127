import { FormControl, Validators } from '@angular/forms';
import { ECompareType, EIndicatorType, ICondition } from '@levent/core';
import { DefaultUICondition } from './default-ui-condition.model';
import { IUICondition } from './ui-condition.interface';

export class UIConditionByPrice<T extends ICondition> extends DefaultUICondition<T> implements IUICondition<T> {
	public override name = 'COMMON.PRICE';
	public override label = 'INDICATORS.PRICE_$';
	public override tooltipText = null;
	public override parametersType = [
		{ parameter: ECompareType.LessThen, label: 'COMMON.BELOW' },
		{ parameter: ECompareType.BiggerThen, label: 'COMMON.ABOVE' },
	];

	constructor(properties: Partial<T & { isBenchmark: boolean }>) {
		super();
		this.conditionFormGroup.addControl(
			'compareType',
			new FormControl(properties.compareType ?? this.parametersType[0].parameter, [Validators.required]),
		);
		this.conditionFormGroup.addControl('isBenchmark', new FormControl(!!properties?.isBenchmark));
		const indicatorType = properties?.rightExpression?.indicatorType ?? null;
		if (indicatorType === null) {
			properties.rightExpression = {
				indicatorType: EIndicatorType.Price,
				parameters: {},
			};
			properties.leftExpression = {
				indicatorType: EIndicatorType.Price,
				parameters: {},
			};
		}

		this.conditionFormGroup.patchValue({ ...(properties ?? {}) }, { emitEvent: false, onlySelf: true });
	}
}
