import { BreakpointState } from '@angular/cdk/layout';
import { ELanguages } from '@levent/core';

export class InitApplicationAction {
	public static readonly type = '[Core-State] init application';
	constructor(public payload: boolean) {}
}

export class ChangeLangAction {
	public static readonly type = '[Core-State] changed lang.';
	constructor(public language: ELanguages) {}
}

export class UpdateDirectionAction {
	public static readonly type = '[Core-State] update direction.';
}

export class UpdateAppStartProcessAction {
	public static readonly type = '[Core-State] update app start process';
	constructor(public payload: number) {}
}

export class IsMobileViewAction {
	public static readonly type = '[Core-State] is mobile view';
	constructor(public payload: boolean) {}
}

export class SetViewStateAction {
	public static readonly type = '[Core-State] setup mobile, tablet modes';
	constructor(public payload: BreakpointState) {}
}
