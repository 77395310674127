import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PreloadingStrategyService extends PreloadingStrategy {
	public preload(route: Route, load: () => Observable<any>): Observable<any> {
		const loadRoute = (delay: number) => (delay > 0 ? timer(delay * 1000).pipe(map(() => load())) : load());
		const connection = (navigator as any).connection;
		const speed: string = connection.effectiveType;
		const slowConnections: Set<string> = new Set<string>(['slow-2g', '2g']);
		if (slowConnections.has(speed)) {
			return of(null);
		}
		const { data } = route;
		if (data) {
			const { loadAfterSeconds, preload } = data;
			if (preload) {
				const delay = loadAfterSeconds ? loadAfterSeconds : 0;
				return loadRoute(delay);
			}
		}
		return of(null);
	}
}
