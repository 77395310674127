import { Id, IInstrument, IInstrumentMapById } from '@levent/core';
import { CoreStateModel, CoreState } from '@lev-store/core';
import { IMarketState } from '@levent/core/DTO/models/market-state';
import { Selector } from '@ngxs/store';
import { InstrumentsState } from './instruments-state';

import { InstrumentsStateModel } from './instruments-state.model';

export class InstrumentsSelectors {
	@Selector([InstrumentsState, CoreState])
	public static pending(pageState: InstrumentsStateModel, coreState: CoreStateModel): boolean {
		return !coreState.pending ? pageState.pending : false;
	}

	@Selector([InstrumentsState])
	public static instrumentMapById(state: InstrumentsStateModel): IInstrumentMapById {
		return state.instrumentMapById;
	}

	@Selector([InstrumentsState])
	public static instrument(state: InstrumentsStateModel): (instrumentId: Id) => IInstrument {
		return (instrumentId: Id) => state.instrumentMapById[instrumentId];
	}

	@Selector([InstrumentsState])
	public static allInstruments(state: InstrumentsStateModel): IInstrument[] {
		return state.instrumentIds.map(id => state.instrumentMapById[id]);
	}

	@Selector([InstrumentsState])
	public static allInstrumentsIds(state: InstrumentsStateModel): Id[] {
		return state.instrumentIds;
	}

	@Selector([InstrumentsState])
	public static currentMarketState(state: InstrumentsStateModel): IMarketState {
		return state.currentMarketState;
	}

	@Selector([InstrumentsState])
	public static marketStateMap(state: InstrumentsStateModel): { [key: string]: IMarketState } {
		return state.marketStateMap;
	}

	@Selector([InstrumentsState])
	public static favouriteInstruments(state: InstrumentsStateModel): IInstrument[] {
		return state.favouriteInstrumentIds.map(id => state.instrumentMapById[id]);
	}

	@Selector([InstrumentsState])
	public static favouriteInstrumentIds(state: InstrumentsStateModel): Id[] {
		return state.favouriteInstrumentIds;
	}
}
