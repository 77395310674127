import { Validators } from '@angular/forms';
import { ECalculationType, ICondition, LevValidators } from '@levent/core';
import { DefaultUICondition } from './default-ui-condition.model';

export class UIConditionHasCalculationType<T extends ICondition> extends DefaultUICondition<T> {
	public override parametersType = [
		{ parameter: ECalculationType.Number, label: '$' },
		{ parameter: ECalculationType.Percent, label: '%' },
	];

	protected validateByCalculationType(): void {
		const calculationType = this.conditionFormGroup.get('rightExpression.parameters.calculationType').value ?? null;
		if (calculationType) {
			this.conditionFormGroup.get('rightExpression.parameters.offset').clearValidators();
			const validators = [Validators.required];
			if (calculationType === ECalculationType.Number) {
				validators.push(LevValidators.MinMaxRange(1, 1000 * 1000, false, 'string'));
			} else if (calculationType === ECalculationType.Percent) {
				validators.push(LevValidators.MinMaxRange(1, 100, false, 'string'));
			}
			this.conditionFormGroup.get('rightExpression.parameters.offset').setValidators(validators);
			this.conditionFormGroup.get('rightExpression.parameters.offset').updateValueAndValidity();
		}
	}
}
