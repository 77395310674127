import { EOrderType, IComplexCondition } from '@levent/core';
import { UIAlertComplexCondition } from './ui-alert-complex-condition.model';
import { UIStrategyComplexCondition } from './ui-strategy-complex-condition.model';
import { IUIStrategyComplexCondition } from './ui-strategy-complex-condition.interface';
import { IUIComplexCondition } from './ui-complex-condition.interface';
import { EComplexConditionType } from './complex-condition-type.enum';

export class UIComplexConditionFactory {
	public static create<T extends IComplexCondition>(
		type: EComplexConditionType.ALERT,
		properties: Partial<T>,
	): IUIComplexCondition<T>;
	public static create<T extends IComplexCondition>(
		type: EComplexConditionType.STRATEGY,
		properties: Partial<T & { quantity: number; buySell: EOrderType }>,
	): IUIStrategyComplexCondition<T>;
	public static create<T extends IComplexCondition>(
		type: EComplexConditionType,
		properties: Partial<T>,
	): IUIComplexCondition<T> | IUIStrategyComplexCondition<T>;
	public static create<T extends IComplexCondition>(
		type: EComplexConditionType,
		properties: Partial<T>,
	): IUIComplexCondition<T> {
		switch (type) {
			case EComplexConditionType.STRATEGY:
				return new UIStrategyComplexCondition(
					properties as Partial<T & { quantity: number; buySell: EOrderType }>,
				);
			case EComplexConditionType.ALERT:
				return new UIAlertComplexCondition(properties);
		}
	}
}
