import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	EIndicator,
	EIndicatorType,
	ICondition,
	INDICATOR_LABEL_MAP,
	ECompareType,
	ECalculationType,
	EIndicatorBarInterval,
	EIndicatorSource,
	EIndicatorComparerType,
} from '@levent/core';
import { LevValidators } from '@levent/core/classes/lev-validators';
import { DefaultUICondition } from './default-ui-condition.model';
import { IUIConditionIndicator } from './ui-condition-indicator.interface';

export class UIConditionIndicator<T extends ICondition>
	extends DefaultUICondition<T>
	implements IUIConditionIndicator<T>
{
	public override name = 'COMMON.INDICATORS';
	public override label = 'COMMON.INDICATORS';
	public override tooltipText = 'COMMON.INDICATORS';
	public override parametersType = [
		{ parameter: ECompareType.LessThen, label: 'COMMON.BELOW' },
		{ parameter: ECompareType.BiggerThen, label: 'COMMON.ABOVE' },
	];
	public intervals: { value: EIndicatorBarInterval; label: string }[] = INDICATOR_INTERVALS;
	public indicatorOptions: { value: EIndicator; label: string }[] = INDICATOR_OPTIONS;

	constructor(properties: Partial<T & { isBenchmark: boolean }>) {
		super();
		this.conditionFormGroup.addControl(
			'compareType',
			new FormControl(properties.compareType ?? this.parametersType[0].parameter, [Validators.required]),
		);
		this.conditionFormGroup.addControl('isBenchmark', new FormControl(!!properties?.isBenchmark));
		const indicatorType = properties?.rightExpression?.indicatorType ?? null;
		if (indicatorType == null) {
			properties.rightExpression = {
				indicatorType: EIndicatorType.Indicator,
				parameters: {},
			};
			properties.leftExpression = {
				indicatorType: EIndicatorType.Indicator,
				parameters: {},
			};
		}
		const indicatorName = properties.rightExpression.parameters?.indicatorName ?? this.indicatorOptions[0].value;
		properties.rightExpression.parameters = {
			...DEFAULT_FORM_VALUE_RIGHT_EXPRESSION_BY_INDICATOR[indicatorName],
			...properties.rightExpression.parameters,
		};
		this.conditionFormGroup.patchValue({ ...(properties ?? {}) }, { emitEvent: false, onlySelf: true });
	}

	public override getRightExpressionFormGroup(): FormGroup {
		return new FormGroup({
			indicatorType: new FormControl(null, [Validators.required]),
			parameters: new FormGroup({
				indicatorName: new FormControl(null, [Validators.required]),
				interval: new FormControl(null, [Validators.required]),
				source: new FormControl(null),
				offset: new FormControl(null, [
					Validators.required,
					LevValidators.MinMaxRange(1, 100, false, 'string'),
				]),
				length: new FormControl(),
				calculationType: new FormControl(null),
				fastLength: new FormControl(),
				slowLength: new FormControl(),
			}),
		});
	}

	public override getConditionId(): string {
		const defaultId = super.getConditionId();
		const indicatorName = this.conditionFormGroup
			.get('rightExpression.parameters.indicatorName')
			.value.split(' ')
			.join('_');
		return `${defaultId}_${indicatorName}`;
	}
}

export const INDICATOR_INTERVALS: { value: EIndicatorBarInterval; label: string }[] = [
	{ value: EIndicatorBarInterval.OneMinute, label: '1' },
	{ value: EIndicatorBarInterval.ThreeMinutes, label: '3' },
	{ value: EIndicatorBarInterval.FiveMinutes, label: '5' },
	{ value: EIndicatorBarInterval.FifteenMinutes, label: '15' },
	{ value: EIndicatorBarInterval.ThirtyMinutes, label: '30' },
	{ value: EIndicatorBarInterval.OneHour, label: '1H' },
	{ value: EIndicatorBarInterval.OneDay, label: '1D' },
	{ value: EIndicatorBarInterval.OneWeek, label: '1W' },
	{ value: EIndicatorBarInterval.OneMonth, label: '1M' },
	{ value: EIndicatorBarInterval.OneYear, label: '1Y' },
	{ value: EIndicatorBarInterval.FiveYears, label: '5Y' },
];

export const INDICATOR_OPTIONS: { value: EIndicator; label: string }[] = [
	{
		value: EIndicator.RSI,
		label: INDICATOR_LABEL_MAP[EIndicator.RSI].shortLabel,
	},
	{
		value: EIndicator.VWAP,
		label: INDICATOR_LABEL_MAP[EIndicator.VWAP].shortLabel,
	},
	{
		value: EIndicator.MACD,
		label: INDICATOR_LABEL_MAP[EIndicator.MACD].shortLabel,
	},
	{
		value: EIndicator.BollingerBands,
		label: INDICATOR_LABEL_MAP[EIndicator.BollingerBands].shortLabel,
	},
	{
		value: EIndicator.SMA,
		label: INDICATOR_LABEL_MAP[EIndicator.SMA].shortLabel,
	},
	{
		value: EIndicator.SMMA,
		label: INDICATOR_LABEL_MAP[EIndicator.SMMA].shortLabel,
	},
	{
		value: EIndicator.StdDev,
		label: INDICATOR_LABEL_MAP[EIndicator.StdDev].shortLabel,
	},
	{
		value: EIndicator.EMA,
		label: INDICATOR_LABEL_MAP[EIndicator.EMA].shortLabel,
	},
];

export const DEFAULT_FORM_VALUE_RIGHT_EXPRESSION_BY_INDICATOR: Record<
	EIndicator,
	{
		indicatorName: EIndicator;
		interval: EIndicatorBarInterval;
		source: EIndicatorSource;
		offset: string;
		length?: string;
		calculationType?: ECalculationType;
		comparatorType?: EIndicatorComparerType;
		fastLength?: string;
		slowLength?: string;
	}
> = {
	[EIndicator.RSI]: {
		indicatorName: EIndicator.RSI,
		length: '10',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.VWAP]: {
		indicatorName: EIndicator.VWAP,
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.MACD]: {
		indicatorName: EIndicator.MACD,
		fastLength: '12',
		slowLength: '26',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.BollingerBands]: {
		indicatorName: EIndicator.BollingerBands,
		length: '12',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.SMA]: {
		indicatorName: EIndicator.SMA,
		length: '20',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		comparatorType: EIndicatorComparerType.Static,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.SMMA]: {
		indicatorName: EIndicator.SMMA,
		length: '20',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.StdDev]: {
		indicatorName: EIndicator.StdDev,
		length: '20',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
	[EIndicator.EMA]: {
		indicatorName: EIndicator.EMA,
		length: '10',
		interval: EIndicatorBarInterval.OneMinute,
		source: EIndicatorSource.Close,
		offset: '',
		calculationType: ECalculationType.Number,
	},
};
