export enum ESvgIcons {
	DASHBOARD = 'icon-dashboard',
	MARKET = 'icon-market',
	STRATEGIES = 'icon-strategy',
	ALERT = 'icon-alert',
	SHARE = 'icon-share',
	SUN = 'sun',
	ALERT_BELL = 'icon-alert-bell',
	STRATEGY_LIBRARY = 'icon-strategy_b',
	REMOVE_INDICATOR = 'icon-remove-indicator',
	REMOVE_CONDITION_FORM_RIGHT_SIDEBAR = 'icon-remove-condition-from-right-sidebar',
	BELL_WITH_BAR = 'icon-bell-with-bar',
	FAVOURITE = 'icon-favorite',
	FAVOURITE_ACTIVE = 'icon-favorite_active',
	ALERT_STATUS = 'icon-alert_status',
	HOME = 'icon-home',
	TOGGLE_FLIP = 'icon-toggle-flip',
	TOGGLE_FLOP = 'icon-toggle-flop',
	HISTORY = 'icon-history',
	EDIT = 'icon-edit',
	BIN = 'icon-bin',
	INFO = 'icon-info',
	LOGO_FULL = 'logo-full',
	WHITE_LOGO = 'white-logo',
	BLACK_LOGO = 'black-logo',
	LOGO_SHORT = 'logo-short',
	PLUS = 'icon-plus',
	SEARCH = 'icon-search',
	DROP_DOWN = 'icon-drop-down',
	DROP_UP = 'icon-drop-up',
	EYE = 'icon-eye',
	AMERICAN_FLAG = 'icon-america-flag',
	CURRENCY_DOLLAR = 'icon-currency-dollar',
	FIGURE_ARROW = 'icon-figure-arrow',
	CHEVRON_UP = 'icon-chevron-up',
	CHEVRON_DOWN = 'icon-chevron-down',
	ARROW_LEFT = 'icon-arrow-left',
	ARROW_RIGHT = 'icon-arrow-right',
	CHECKED = 'icon-checked',
	LIST_VIEW = 'icon-list-view',
	GALLERY_VIEW = 'icon-gallery-view',
	CHART = 'icon-chart',
	FEATHER_DELETE = 'icon-feather-delete',
	CALENDAR = 'icon-calendar',
	BACKTEST = 'icon-backtest',
	BURGER = 'icon-burger',
	BELL = 'icon-bell',
	LOGO_MOBILE = 'levent-logo-mobile',
	INSTRUMENT_SIDEBAR_TIP = 'instrument-sidebar-tip',
	CLOSE_IN_CIRCLE = 'close-tag',
	BROKER_VIRTUAL_PROFILE = 'icon-broker-virtual-account',
	BROKER_LIVE_ACCOUNT = 'icon-broker-live-account',
	CHECKED_IN_CIRCLE_NOT_FILLED = 'icon-checked-in-circle-not-filled',
	LINK = 'icon-link',
	LOGOUT = 'icon-logout',
}
