import { PortalModule } from '@angular/cdk/portal';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@levent/core/core.module';
import { ApiInterceptor, AuthInterceptor, QueueInterceptor } from '@levent/core';

import { darkTheme, lightTheme, ThemeModule, ThemesEnum } from '@lev-shared/app-theme';
import { IconRegistryModule } from '@lev-shared/icon-registry/icon-registry.module';
import { TranslateModule } from '@ngx-translate/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { AppInitService, initializeApp } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';

import { AppComponent } from './app.component';

const maskConfig: Partial<IConfig> = {
	validation: false,
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		IconRegistryModule,
		CoreModule.forRoot(),
		AppStoreModule,
		MatNativeDateModule,
		MatMomentDateModule,
		PortalModule,
		ThemeModule.forRoot({
			themes: [darkTheme, lightTheme],
			active: ThemesEnum.DARK,
		}),
		NgxMaskModule.forRoot(maskConfig),
		TranslateModule,
	],
	providers: [
		MatIconRegistry,
		AppInitService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppInitService],
			multi: true,
		},
		{
			provide: Window,
			useValue: window,
		},
		{
			provide: MAT_TABS_CONFIG,
			useValue: { animationDuration: '0ms' },
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: QueueInterceptor,
		// 	multi: true,
		// },
		// {
		// 	provide: ErrorHandler,
		// 	useValue: createErrorHandler({
		// 		showDialog: true,
		// 	}),
		// },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	public static injector: Injector;

	constructor(private readonly injector: Injector) {
		AppModule.injector = injector;
	}
}
