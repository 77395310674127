export enum EIndicatorType {
	Price = 'Price',
	Volume = 'Volume',
	TrailingStop = 'TrailingStop',
	Profit = 'Profit',
	Loss = 'Loss',
	Spread = 'Spread',
	TradeNow = 'TradeNow',
	Indicator = 'Indicator',
}
