import { Id } from '@levent/core';
import { IUserConfiguration } from '@levent/core/DTO/models/user-configuration';

export class LoginAction {
	public static readonly type = '[User] Login with credentials.';
	constructor(public credentials: { username: string; password: string }) {}
}

export class RegistrationAction {
	public static readonly type = '[User] Start user registration.';
	constructor(public username: string, public password: string, public firstName: string, public lastName: string) {}
}

export class ErrorRegistrationAction {
	public static readonly type = '[User] error user registration.';
}

export class ConfirmRegistrationAction {
	public static readonly type = '[User] Confirm user registration.';
	constructor(public username: string, public password: string, public code: number) {}
}

export class LoginWithRefreshTokenAction {
	public static readonly type = '[User] Login with refresh token.';
}

export class SuccessLoginAction {
	public static readonly type = '[User] Success login.';
	constructor(public token: string, public refreshToken: string, public expiresIn: number) {}
}

export class ErrorLoginAction {
	public static readonly type = '[User] Can not login.';
	constructor(public incorrectLoginOrPassword?: boolean) {}
}

export class LogoutAction {
	public static readonly type = '[User] logout';
}

export class FetchUserConfigurationDetailsAction {
	public static readonly type = '[User] fetch user configuration details.';
}

export class FetchUserConfigurationDetailsSuccessAction {
	public static readonly type = '[User] fetch user configuration details success.';
	constructor(public payload: IUserConfiguration) {}
}

export class FetchUserConfigurationDetailsErrorAction {
	public static readonly type = '[User] fetch user configuration details error.';
}
