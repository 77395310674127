import { animate, animation, keyframes, style } from '@angular/animations';
import { ANIMATION_DURATION } from './animation-duration';

export const flipInY = animation(
	[
		animate(
			`{{ duration }}ms {{delay}}ms`,
			keyframes([
				style({
					visibility: 'visible',
					transform: 'perspective(400px) rotate3d(0, 1, 0, {{ degrees }}deg)',
					opacity: 0,
					easing: 'ease-in',
					offset: 0,
				}),
				style({
					transform: 'perspective(400px) rotate3d(0, 1, 0, -20deg)',
					opacity: 0.5,
					easing: 'ease-in',
					offset: 0.4,
				}),
				style({
					transform: 'perspective(400px) rotate3d(0, 1, 0, 10deg)',
					opacity: 1,
					easing: 'ease-in',
					offset: 0.6,
				}),
				style({
					transform: 'perspective(400px) rotate3d(0, 1, 0, -5deg)',
					easing: 'ease',
					offset: 0.8,
				}),
				style({ transform: 'perspective(400px)', easing: 'ease', offset: 1 }),
			]),
		),
	],
	{ params: { degrees: 90, duration: ANIMATION_DURATION, delay: 0 } },
);
export const flipOutY = animation(
	[
		animate(
			'{{duration}}ms {{delay}}ms',
			keyframes([
				style({ transform: 'perspective(400px)', opacity: 1, easing: 'ease', offset: 0 }),
				style({
					transform: 'perspective(400px) rotate3d(0, 1, 0, -15deg)',
					opacity: 1,
					easing: 'ease',
					offset: 0.3,
				}),
				style({
					transform: 'perspective(400px) rotate3d(0, 1, 0, {{degrees}}deg)',
					opacity: 0,
					easing: 'ease',
					offset: 1,
				}),
			]),
		),
	],
	{ params: { degrees: 90, duration: ANIMATION_DURATION, delay: 0 } },
);
