import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AddToFavouriteInstrumentAction,
	AddToFavouriteInstrumentErrorAction,
	AddToFavouriteInstrumentSuccessAction,
	RemoveFromFavouriteInstrumentAction,
	RemoveFromFavouriteInstrumentErrorAction,
	RemoveFromFavouriteInstrumentSuccessAction,
	ToggleFavouriteInstrumentAction,
} from './instruments.actions';
import { IResponseRecords, IInstrumentMapById, ESignalRMessage, EApi } from '@levent/core';
import { IMarketState } from '@levent/core/DTO/models/market-state';
import { UtilsService } from '@levent/core/services';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';

import { catchError, concat, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EConnectionNames } from '@lev-store/shared/signalR/signalR-state.model';
import { SendSignalRMessageAction } from '@lev-store/shared/signalR/signalR.actions';

import { defaults, InstrumentsStateModel } from './instruments-state.model';

import {
	AddInstrumentInstanceInViewAction,
	FetchInstrumentsByIdAction,
	FetchInstrumentsAction,
	OnTickInstrumentAction,
	RemoveInstrumentInstanceInViewAction,
	SearchInstrumentsAction,
	UpdateInstrumentMapByIdAction,
	FetchMarketStateAction,
	FetchMarketStateByDateAction,
	FetchMarketStateSuccessAction,
	FetchMarketStateErrorAction,
	FetchMarketStateByDateSuccessAction,
	FetchMarketStateByDateErrorAction,
} from './instruments.actions';
import { Id, IInstrument } from '@levent/core';

@State<InstrumentsStateModel>({
	name: 'instrumentsState',
	defaults,
})
@Injectable()
export class InstrumentsState implements NgxsOnInit {
	public ngxsOnInit({ dispatch }: StateContext<InstrumentsStateModel>): void {
		dispatch(new FetchMarketStateAction());
	}

	constructor(private readonly httpClient: HttpClient) {}

	@Action(AddInstrumentInstanceInViewAction)
	public addInstrumentInstanceInView(
		{ patchState, getState, dispatch }: StateContext<InstrumentsStateModel>,
		{ payload }: AddInstrumentInstanceInViewAction,
	): void {
		const { instrumentInstanceInView } = getState();
		const instrumentMap: { [key: Id]: number } = {};
		const arrIds: Id[] = UtilsService.getCorrectArrIds(payload);
		const subscribeIds: Id[] = [];
		for (const id of arrIds) {
			const count = instrumentInstanceInView[id] ? instrumentInstanceInView[id] + 1 : 1;
			instrumentMap[id] = count;
			if (count === 1) {
				subscribeIds.push(id);
			}
		}
		if (subscribeIds.length) {
			dispatch(
				new SendSignalRMessageAction(
					EConnectionNames.HUB_TICKS,
					ESignalRMessage.SUBSCRIBE_TO_TICKS,
					subscribeIds.map(id => Number(id)),
				),
			);
		}
		patchState({
			instrumentInstanceInView: {
				...instrumentInstanceInView,
				...instrumentMap,
			},
		});
	}

	@Action(RemoveInstrumentInstanceInViewAction)
	public removeInstrumentInstanceInView(
		{ patchState, getState, dispatch }: StateContext<InstrumentsStateModel>,
		{ payload }: RemoveInstrumentInstanceInViewAction,
	): void {
		const { instrumentInstanceInView } = getState();
		const instrumentMap: { [key: Id]: number } = {};
		const arrIds: Id[] = UtilsService.getCorrectArrIds(payload);
		const unsubscribeIds: Id[] = [];
		for (const id of arrIds) {
			const count = instrumentInstanceInView[id] - 1;
			instrumentMap[id] = count;
			if (count < 1) {
				unsubscribeIds.push(id);
			}
		}
		if (unsubscribeIds.length) {
			dispatch(
				new SendSignalRMessageAction(
					EConnectionNames.HUB_TICKS,
					ESignalRMessage.UNSUBSCRIBE_TO_TICKS,
					unsubscribeIds.map(id => Number(id)),
				),
			);
		}
		patchState({
			instrumentInstanceInView: {
				...instrumentInstanceInView,
				...instrumentMap,
			},
		});
	}

	@Action(OnTickInstrumentAction)
	public onTickInstrument(
		{ setState, getState }: StateContext<InstrumentsStateModel>,
		{ payload }: OnTickInstrumentAction,
	): void {
		const { instrumentMapById } = getState();
		if (instrumentMapById.hasOwnProperty(payload.instrumentId)) {
			setState(
				patch({
					instrumentMapById: patch({
						[payload.instrumentId]: patch({
							...instrumentMapById[payload.instrumentId],
							currentPrice: payload.price,
							volume: payload.volume,
						} as IInstrument),
					}),
				}),
			);
		}
	}

	@Action(SearchInstrumentsAction)
	public searchInstruments(
		{ dispatch }: StateContext<InstrumentsStateModel>,
		{ searchStr }: SearchInstrumentsAction,
	): void {
		dispatch(new FetchInstrumentsAction({ searchStr: searchStr ? `${searchStr}` : '' }));
	}

	@Action(FetchInstrumentsAction)
	public fetchInstruments(
		{ patchState, dispatch }: StateContext<InstrumentsStateModel>,
		{ payload }: FetchInstrumentsAction,
	): Observable<IResponseRecords<IInstrument>> {
		patchState({ pending: true });
		const payloadRequest = {
			page: 1,
			count: 20,
			filters: payload.index ? [payload.index] : [],
			searchedValue: payload.searchStr,
		};
		return this.httpClient.post<IResponseRecords<IInstrument>>(`${EApi.INSTRUMENTS}`, payloadRequest).pipe(
			tap((resp: IResponseRecords<IInstrument>) => {
				dispatch(new UpdateInstrumentMapByIdAction(resp.data));
				if (payload.actionType) {
					dispatch({ payload: resp.data, type: payload.actionType });
				}
				patchState({ pending: false });
			}),
			catchError(error => {
				patchState({ pending: false });
				return throwError(() => error);
			}),
		);
	}

	@Action(FetchInstrumentsByIdAction)
	public fetchInstrumentById(
		{ getState, dispatch }: StateContext<InstrumentsStateModel>,
		{ instrumentIds }: FetchInstrumentsByIdAction,
	): Observable<IInstrument> {
		const { instrumentMapById } = getState();
		const requests: Observable<IInstrument>[] = [];
		for (const instrumentId of instrumentIds) {
			if (!instrumentMapById[instrumentId]) {
				const request = this.httpClient.get<IInstrument>(`${EApi.INSTRUMENTS}/${instrumentId}`).pipe(
					tap((instrument: IInstrument) => {
						dispatch(new UpdateInstrumentMapByIdAction([instrument]));
					}),
					catchError(error => {
						return throwError(() => error);
					}),
				);
				requests.push(request);
			}
		}

		return concat(...requests);
	}

	@Action(UpdateInstrumentMapByIdAction) public updateInstrumentMapById(
		{ getState, patchState }: StateContext<InstrumentsStateModel>,
		{ instruments }: UpdateInstrumentMapByIdAction,
	): void {
		const { instrumentMapById, instrumentIds, favouriteInstrumentIds } = getState();
		const newFavouriteInstrumentIdsSet = new Set(favouriteInstrumentIds);
		const newInstrumentIdsSet = new Set([
			...instrumentIds,
			...instruments.map(instrument => {
				if (instrument.isInWatchList) {
					newFavouriteInstrumentIdsSet.add(instrument.instrumentId);
				}
				return instrument.instrumentId;
			}),
		]);
		const newInstrumentMapById: IInstrumentMapById = {
			...instrumentMapById,
			...instruments
				// TODO: Remove after update info about instruments on backend.
				.map(inst => ({
					...inst,
					iconUrl: inst?.iconUrl || 'https://s3.eu-west-1.amazonaws.com/mediaborsa.levent.io/US/EOG.png',
				}))
				.reduce(
					(acc, current) => ({
						...acc,
						[current.instrumentId]: current,
					}),
					{},
				),
		};
		patchState({
			instrumentMapById: newInstrumentMapById,
			instrumentIds: Array.from(newInstrumentIdsSet),
			favouriteInstrumentIds: Array.from(newFavouriteInstrumentIdsSet),
		});
	}

	@Action(FetchMarketStateAction)
	public fetchMarketState({ dispatch, patchState }: StateContext<InstrumentsStateModel>): Observable<IMarketState> {
		return this.httpClient.get<IMarketState>(EApi.MARKET_STATE).pipe(
			tap((resp: IMarketState) => {
				patchState({ pending: false });
				dispatch(new FetchMarketStateSuccessAction(resp));
			}),
			catchError(error => {
				patchState({ pending: false });
				dispatch(new FetchMarketStateErrorAction());
				return throwError(() => error);
			}),
		);
	}

	@Action(FetchMarketStateSuccessAction) fetchMarketStateSuccess(
		{ getState, patchState }: StateContext<InstrumentsStateModel>,
		{ payload }: FetchMarketStateSuccessAction,
	): void {
		patchState({ currentMarketState: payload });
	}

	@Action(FetchMarketStateByDateAction)
	public fetchMarketStateByDate(
		{ dispatch, patchState }: StateContext<InstrumentsStateModel>,
		{ payload }: FetchMarketStateByDateAction,
	): Observable<IMarketState> {
		return this.httpClient.get<IMarketState>(`${EApi.CALENDAR}?from=${payload.from}&to=${payload.to}`).pipe(
			tap((resp: IMarketState) => {
				dispatch(new FetchMarketStateByDateSuccessAction({ ...payload, marketState: resp }));
			}),
			catchError(error => {
				dispatch(new FetchMarketStateByDateErrorAction());
				return throwError(() => error);
			}),
		);
	}

	@Action(FetchMarketStateByDateSuccessAction) fetchMarketStateByDateSuccess(
		{ setState }: StateContext<InstrumentsStateModel>,
		{ payload }: FetchMarketStateByDateSuccessAction,
	): void {
		setState(
			patch({
				marketStateMap: patch({
					[`${payload.from}__${payload.to}`]: payload.marketState,
				}),
			}),
		);
	}

	@Action(ToggleFavouriteInstrumentAction) public toggleFavouriteInstrument(
		{ setState, getState, dispatch }: StateContext<InstrumentsStateModel>,
		{ instrumentId }: ToggleFavouriteInstrumentAction,
	): void {
		const { favouriteInstrumentIds } = getState();
		if (favouriteInstrumentIds.includes(+instrumentId)) {
			dispatch(new RemoveFromFavouriteInstrumentAction(instrumentId));
		} else {
			dispatch(new AddToFavouriteInstrumentAction(instrumentId));
		}
	}

	@Action(AddToFavouriteInstrumentAction) public addToFavouriteInstrument(
		{ dispatch }: StateContext<InstrumentsStateModel>,
		{ instrumentId }: AddToFavouriteInstrumentAction,
	): Observable<void> {
		const body = { instrumentIds: [+instrumentId] };
		return this.httpClient.post<void>(EApi.INSTRUMENTS_WATCHLIST, body).pipe(
			tap(() => {
				dispatch(new AddToFavouriteInstrumentSuccessAction(instrumentId));
			}),
			catchError(error => {
				dispatch(new AddToFavouriteInstrumentErrorAction());
				return throwError(() => error);
			}),
		);
	}

	@Action(AddToFavouriteInstrumentSuccessAction) public addToFavouriteInstrumentSuccess(
		{ setState }: StateContext<InstrumentsStateModel>,
		{ instrumentId }: AddToFavouriteInstrumentSuccessAction,
	): void {
		setState(
			patch({
				favouriteInstrumentIds: append<Id>([+instrumentId]),
				instrumentMapById: patch({
					[instrumentId]: patch({ isInWatchList: true }),
				}),
			}),
		);
	}

	@Action(RemoveFromFavouriteInstrumentAction) public removeFromFavouriteInstrument(
		{ dispatch }: StateContext<InstrumentsStateModel>,
		{ instrumentId }: RemoveFromFavouriteInstrumentAction,
	): Observable<void> {
		const body = { instrumentIds: [+instrumentId] };
		return this.httpClient.delete<void>(EApi.INSTRUMENTS_WATCHLIST, { body }).pipe(
			tap(() => {
				dispatch(new RemoveFromFavouriteInstrumentSuccessAction(instrumentId));
			}),
			catchError(error => {
				dispatch(new RemoveFromFavouriteInstrumentErrorAction());
				return throwError(() => error);
			}),
		);
	}

	@Action(RemoveFromFavouriteInstrumentSuccessAction) public removeFromFavouriteInstrumentSuccess(
		{ setState }: StateContext<InstrumentsStateModel>,
		{ instrumentId }: RemoveFromFavouriteInstrumentSuccessAction,
	): void {
		setState(
			patch({
				favouriteInstrumentIds: removeItem<Id>(id => id === +instrumentId),
				instrumentMapById: patch({
					[instrumentId]: patch({ isInWatchList: false }),
				}),
			}),
		);
	}
}
