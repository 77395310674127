import { NumberInput } from '@angular/cdk/coercion';
import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmData } from '@levent/core/interfaces/confirm-data.interface';

@Component({
	selector: 'lev-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
	public title: string;
	public description: string;
	public confirmText: string;
	public confirmValue: boolean | NumberInput;
	public cancelText: string;
	public cancelValue: boolean | NumberInput;
	public alertMode: boolean;
	public hiddenCloseButton: boolean;
	public closeValue: boolean | NumberInput;
	public descriptionTemplate: TemplateRef<any>;

	constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) data: IConfirmData) {
		this.title = data.title;
		this.description = data.description;
		this.confirmText = data.confirmText || (data.alertMode ? 'COMMON.OK' : 'COMMON.YES');
		this.cancelText = data.cancelText || 'COMMON.NO';
		this.confirmValue = data.confirmValue || true;
		this.cancelValue = data.cancelValue || false;
		this.closeValue = data.closeValue || false;
		this.descriptionTemplate = data.descriptionTemplate;
		this.alertMode = data.alertMode;
		this.hiddenCloseButton = data.hiddenCloseButton;
	}
}
