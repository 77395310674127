import { EIndicatorType, ICondition } from '@levent/core';
import { UIConditionHasCalculationType } from './ui-condition-has-calculation-type';
import { IUICondition } from './ui-condition.interface';

export class UIConditionTrailingStop<T extends ICondition>
	extends UIConditionHasCalculationType<T>
	implements IUICondition<T>
{
	public override name: 'INDICATORS.TRAILING_STOP';
	public override label = 'INDICATORS.TRAILING_STOP';
	public override tooltipText = 'INDICATORS.TRAILING_STOP_TOOLTIP';

	constructor(properties: Partial<T>) {
		super();
		const indicatorType = properties?.rightExpression?.indicatorType ?? null;
		if (indicatorType === null) {
			properties.rightExpression = {
				indicatorType: EIndicatorType.TrailingStop,
				parameters: {},
			};
			properties.leftExpression = {
				indicatorType: EIndicatorType.TrailingStop,
				parameters: {},
			};
		}
		const calculationType = properties?.rightExpression?.parameters?.calculationType ?? null;
		if (calculationType === null) {
			properties.rightExpression.parameters.calculationType = this.parametersType[0].parameter;
		}
		this.conditionFormGroup.patchValue({ ...(properties ?? {}) }, { emitEvent: false, onlySelf: true });
		this.updateDependenciesAndValidity();
	}

	public override updateDependenciesAndValidity(): void {
		this.validateByCalculationType();
	}
}
