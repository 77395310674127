import { animate, style, transition, trigger } from '@angular/animations';
import { ANIMATION_DURATION } from './animation-duration';
const defaultConfig = {
	trigger: 'slideInOutRight',
	duration: ANIMATION_DURATION,
};
export const slideInOutRight = (config?: { trigger?: string; duration?: number }) => {
	const conf = {
		...defaultConfig,
		...(config || {}),
	};
	return trigger(conf.trigger, [
		transition(':enter', [
			style({ transform: 'translateX(100%)', opacity: 0 }),
			animate(`${conf.duration}ms ease-in`, style({ transform: 'translateX(0%)', opacity: 1 })),
		]),
		transition(':leave', [
			animate(`${conf.duration}ms ease-in`, style({ transform: 'translateX(100%)', opacity: 0 })),
		]),
	]);
};
