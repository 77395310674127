import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ECompareType, EIndicatorType, ICondition } from '@levent/core';
import { DefaultUICondition } from './default-ui-condition.model';
import { IUICondition } from './ui-condition.interface';

export class UiConditionTradeNow<T extends ICondition> extends DefaultUICondition<T> implements IUICondition<T> {
	constructor(properties: Partial<T & { isBenchmark: boolean }>) {
		super();
		this.conditionFormGroup.addControl(
			'compareType',
			new FormControl(properties.compareType ?? ECompareType.BiggerThen, [Validators.required]),
		);
		this.conditionFormGroup.addControl('isBenchmark', new FormControl(!!properties?.isBenchmark));
		const indicatorType = properties?.rightExpression?.indicatorType ?? null;
		if (indicatorType === null) {
			properties.rightExpression = {
				indicatorType: EIndicatorType.TradeNow,
				parameters: {},
			};
			properties.leftExpression = {
				indicatorType: EIndicatorType.TradeNow,
				parameters: {},
			};
		}
		this.conditionFormGroup.patchValue({ ...(properties ?? {}) }, { emitEvent: false, onlySelf: true });
	}

	public override getRightExpressionFormGroup(): FormGroup {
		return new FormGroup({
			indicatorType: new FormControl(null, [Validators.required]),
			parameters: new FormGroup({}),
		});
	}
}
