import {
	HttpTransportType,
	HubConnection,
	IHttpConnectionOptions,
	IHubProtocol,
	ILogger,
	IRetryPolicy,
	ISubscription,
	LogLevel,
} from '@microsoft/signalr';

export interface SignalROptions {
	connectionName: EConnectionNames;

	/**
	 * URL of the SignalR endpoint.
	 */
	baseUrl?: string;

	/**
	 * URL of the SignalR endpoint.
	 */
	url?: string;

	/**
	 * Specifies a specific HTTP transport type.
	 */
	transportType?: HttpTransportType;

	/**
	 *
	 */
	httpConnectionOptions?: IHttpConnectionOptions;

	/**
	 * Retry policy implementation
	 */
	reconnectPolicy?: IRetryPolicy;

	/**
	 * Retry delays
	 */
	retryDelays?: number[];

	/**
	 * Automatic reconnect
	 */
	automaticReconnect?: boolean;

	/**
	 *
	 */
	protocol?: IHubProtocol;

	logging?: string | LogLevel | ILogger;

	keepAliveIntervalInMilliseconds?: number;
	serverTimeoutInMilliseconds?: number;

	listenerConfigs?: ListenerConfig[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function noop(..._args: unknown[]) {
	return function () {
		// noop
	};
}

/**
 * This error is thrown where there is no `type` (or custom `typeKey`) property
 * on the message that came from the server side socket
 */
export class TypeKeyPropertyMissingError extends Error {
	constructor() {
		super(`Property type is missing on the socket message`);
	}
}

export enum EConnectionNames {
	HUB_TICKS = 'hub-ticks',
	HUB_NOTIFICATIONS = 'hub-notifications',
}

export enum EListenerConfigMethodNames {
	ON_TICK = 'onTick',
	ON_NOTIFICATION = 'onNotification',
}

export interface ListenerConfig {
	methodName: EListenerConfigMethodNames;
	actionType: string;
}

type connectionMapType = { [key in EConnectionNames]?: HubConnection };
type streamSubscriptionsMapType = { [key in EConnectionNames]?: ISubscription<unknown>[] };

export class SignalRStateModel {
	constructor(
		public connectionMap: connectionMapType = {},
		public streamSubscriptionsMap: streamSubscriptionsMapType = {},
	) {}
}

export const defaults: SignalRStateModel = new SignalRStateModel();
