import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthSelectors } from '@lev-store/page/auth';
import { ERoutes } from '@levent/core';

@Injectable({
	providedIn: 'root',
})
export class PagesCanActivateChildGuard implements CanActivateChild {
	constructor(private store: Store, private router: Router) {}
	public canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		let result = !!this.store.selectSnapshot(AuthSelectors.token);
		if (!result) {
			this.router.navigateByUrl(`/${ERoutes.AUTH}/${ERoutes.LOGIN}`)?.then();
		}
		return result;
	}
}
