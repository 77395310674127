import { Directionality } from '@angular/cdk/bidi';
import { Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ELanguages } from '@levent/core';
import { Action, State, StateContext } from '@ngxs/store';
import { CoreStateModel, defaults } from './core-state.model';

import {
	ChangeLangAction,
	InitApplicationAction,
	IsMobileViewAction,
	SetViewStateAction,
	UpdateAppStartProcessAction,
	UpdateDirectionAction,
} from './core.actions';

@State<CoreStateModel>({
	name: 'coreState',
	defaults,
})
@Injectable()
export class CoreState {
	private readonly FINISH_VALUE_PROGRESS: number = 100;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly dir: Directionality,
	) {}

	@Action(InitApplicationAction)
	public initApplication(ctx: StateContext<CoreStateModel>): void {
		ctx.dispatch(new UpdateAppStartProcessAction(this.FINISH_VALUE_PROGRESS));
	}

	@Action(ChangeLangAction)
	public changeLang({ patchState, dispatch }: StateContext<CoreStateModel>, { language }: ChangeLangAction): void {
		this.document.documentElement.lang = language;
		patchState({ language });
		dispatch(new UpdateDirectionAction());
	}

	@Action(UpdateDirectionAction)
	public updateDirection({ patchState, getState }: StateContext<CoreStateModel>): void {
		const { language } = getState();
		const dir = language === ELanguages.HE ? 'rtl' : 'ltr';
		this.document.dir = dir;
		this.dir.change.emit(dir);
		patchState({ dir });
	}

	@Action(UpdateAppStartProcessAction)
	public updateAppStartProcess(
		{ patchState }: StateContext<CoreStateModel>,
		{ payload }: UpdateAppStartProcessAction,
	): void {
		patchState({ appProgress: payload });
	}

	@Action(IsMobileViewAction)
	public IsMobileView({ patchState }: StateContext<CoreStateModel>, { payload }: IsMobileViewAction): void {
		patchState({ isMobileView: payload });
	}

	@Action(SetViewStateAction)
	public SetViewStateAction({ patchState }: StateContext<CoreStateModel>, { payload }: SetViewStateAction): void {
		const { breakpoints } = payload;
		const isTabletView = breakpoints[Breakpoints.Small] || breakpoints[Breakpoints.Medium];
		const isMobileView = breakpoints[Breakpoints.XSmall];
		patchState({ isTabletView, isMobileView });
	}
}
