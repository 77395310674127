import { NgModule } from '@angular/core';
import { CoreState } from '@lev-store/core';
import { AuthState } from '@lev-store/page/auth';
import { AppRouterStateSerializer } from '@lev-store/shared/router/router-state-serializer';
import { AlertWizardState } from '@lev-store/shared/wizards/alert-wizard';
import { StrategyWizardState } from '@lev-store/shared/wizards/strategy-wizard';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
// import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';

@NgModule({
	imports: [
		NgxsModule.forRoot([CoreState, AuthState]),
		// [BUG]: Route not updated via ReduxDevTools https://github.com/ngxs/store/issues/1640
		// [BUG]: NgxsReduxDevtoolsPluginModule logs actions in wrong order when dispatching actions from async actions
		// https://github.com/ngxs/store/issues/139
		// [BUG]: No displays map values into state https://github.com/ngxs/store/issues/314
		NgxsFormPluginModule.forRoot(),
		NgxsStoragePluginModule.forRoot({
			key: [CoreState, AuthState, StrategyWizardState, AlertWizardState],
			storage: StorageOption.LocalStorage,
		}),
		NgxsRouterPluginModule.forRoot(),
		// NgxsReduxDevtoolsPluginModule.forRoot(),
	],
	providers: [
		{
			provide: RouterStateSerializer,
			useClass: AppRouterStateSerializer,
			deps: [Store],
		},
	],
})
export class AppStoreModule {}
