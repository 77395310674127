export enum EApi {
	STRATEGIES = 'strategies',
	ALERTS = 'alerts',
	INSTRUMENTS = 'instruments',
	INSTRUMENTS_WATCHLIST = 'instruments/watchlist',
	HISTORICAL = 'historical',
	TEMPLATES = 'templates',
	START = 'start',
	STOP = 'stop',
	BROKER_INFO = 'broker/info',
	MARKET_STATE = 'data/market-state',
	CALENDAR = 'data/calendar',
	USER_CONFIGURATIONS_DETAILS = 'user-configuration/details',
}
