import { InjectionToken } from '@angular/core';
import { EThemeProperties } from '@levent/core/enums/theme-properties.enum';

export const THEMES = new InjectionToken('THEMES');
export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

export enum ThemesEnum {
	LIGHT = 'light',
	DARK = 'dark',
}

type IProperties = {
	[key in typeof EThemeProperties[keyof typeof EThemeProperties]]: string | number;
};
export interface Theme {
	name: ThemesEnum.LIGHT | ThemesEnum.DARK;
	properties: IProperties;
}

export interface ThemeOptions {
	themes: Theme[];
	active: string;
}
