import { Injectable, NgZone } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class NextTickService {
	constructor(private readonly zone: NgZone, private readonly _window: Window) {}

	public run(f: () => any, delay: number = 0): number {
		let timeoutId!: number;
		this.zone.runOutsideAngular(() => {
			timeoutId = this._window.setTimeout(f, delay);
		});
		return timeoutId;
	}

	public cancel(timeoutId: number): void {
		this.zone.runOutsideAngular(() => {
			this._window.clearTimeout(timeoutId);
		});
	}
}
