import { IStrategy } from '@levent/core';

export class StrategyWizardStateModel {
	constructor(
		public pending: boolean = false,
		public model: Partial<IStrategy> = {},
		public dirty: boolean = false,
		public status: string = '',
		public errors: any = {},
	) {}
}

export const defaults: StrategyWizardStateModel = new StrategyWizardStateModel();
