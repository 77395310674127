import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';
import { ANIMATION_DURATION } from '@levent/core/animations/animation-duration';
const CLOSED_SIDEBAR_WIDTH = 90;
const OPENED_SIDEBAR_WIDTH = 190;

export enum AnimationStates {
	OPEN = 'open',
	CLOSED = 'closed',
	CLOSED_MOBILE = 'closedMobile',
	OPEN_MOBILE = 'openMobile',
}

interface SidenavAnimationConfig {
	position: 'left' | 'right';
	openedWidth: number;
	closedWidth: number;
}
const sidenavAnimation = (config: Partial<SidenavAnimationConfig>): AnimationTriggerMetadata => {
	const openedWidth = config.openedWidth || OPENED_SIDEBAR_WIDTH;
	const closedWidth = config.closedWidth || CLOSED_SIDEBAR_WIDTH;
	return trigger('openCloseSidenav', [
		state(
			AnimationStates.OPEN,
			style({
				width: `${openedWidth}px`,
				transform: 'translate(0, 0)',
				opacity: '1',
			}),
		),
		state(
			AnimationStates.CLOSED,
			style({
				transform: 'translate(0, 0)',
				width: `${closedWidth}px`,
				opacity: '1',
			}),
		),
		state(
			AnimationStates.OPEN_MOBILE,
			style({
				width: `${openedWidth}px`,
				transform: 'translate(0, 0)',
				opacity: '1',
			}),
		),
		state(
			AnimationStates.CLOSED_MOBILE,
			style({
				opacity: '0.3',
				width: `${openedWidth}px`,
				transform: `translate(-${openedWidth}}px, -${closedWidth}px)`,
			}),
		),
		transition(`${AnimationStates.OPEN} <=> ${AnimationStates.CLOSED}`, [animate(ANIMATION_DURATION)]),
		transition(`${AnimationStates.OPEN_MOBILE} <=> ${AnimationStates.CLOSED_MOBILE}`, [
			animate(ANIMATION_DURATION),
		]),
	]);
};

const sidenavContentAnimation = (config: Partial<SidenavAnimationConfig>): AnimationTriggerMetadata => {
	const position = config.position || 'left';
	const openedWidth = config.openedWidth || OPENED_SIDEBAR_WIDTH;
	const closedWidth = config.closedWidth || CLOSED_SIDEBAR_WIDTH;
	return trigger('openCloseSidenavContent', [
		state(
			AnimationStates.OPEN,
			style({
				[`margin-${position}`]: `${openedWidth}px`,
			}),
		),
		state(
			AnimationStates.CLOSED,
			style({
				[`margin-${position}`]: `${closedWidth}px`,
			}),
		),
		state(
			AnimationStates.OPEN_MOBILE,
			style({
				filter: 'blur(1px)',
				transform: `translate(${openedWidth}px, ${closedWidth}px)`,
				background: 'var(--deep-background)',
				'border-radius': '24px',
			}),
		),
		state(
			AnimationStates.CLOSED_MOBILE,
			style({
				transform: 'translate(0, 0)',
			}),
		),
		transition(`${AnimationStates.OPEN} <=> ${AnimationStates.CLOSED}`, [animate(ANIMATION_DURATION)]),
		transition(`${AnimationStates.OPEN_MOBILE} <=> ${AnimationStates.CLOSED_MOBILE}`, [
			animate(ANIMATION_DURATION),
		]),
	]);
};

export function sidenavAnimations(config?: Partial<SidenavAnimationConfig>): AnimationTriggerMetadata[] {
	return [sidenavContentAnimation(config || {}), sidenavAnimation(config || {})];
}
